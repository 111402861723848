import React, { Component } from "react";
import {
  NUMBER,
  minimumMessageText,
  maximumMessageText
} from "../Utility/TypeValues";

class ValidateComponent extends Component {
  render() {
    const { questionContent } = this.props.question;
    let errorMessage = questionContent;
    const { answer } = this.props;
    if (
      this.props.question.type === NUMBER &&
      (answer.error === minimumMessageText ||
        answer.error === maximumMessageText)
    ) {
      errorMessage = this.props.question.numericQuestionContent;
    }
    return questionContent && answer.error !== "" ? (
      <div className="error_message">{errorMessage[answer.error]}</div>
    ) : (
      ""
    );
  }
}

export default ValidateComponent;
