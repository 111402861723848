import React, { Component } from "react";
import { connect } from "react-redux";
import ThankyouComponent from "../Components/ThankyouComponent";
import LanguageComponent from "../Components/LanguageComponent";
import ProgressComponent from '../Components/ProgressComponent';

class ThankyouContainer extends Component {
  render () {
    const { conduct } = this.props;

    return (
      <div className="page" id="thank_you_page">
        {conduct.thankyou_show === true? <ProgressComponent conduct={this.props.conduct} complete={this.props.complete}/> : null}
        <LanguageComponent
          conduct={conduct}
        />
        <ThankyouComponent conduct={conduct} />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    conduct: state.conductReducer,
    complete: state.appReducer.complete
  };
};

export default connect(mapStateToProps)(ThankyouContainer);
