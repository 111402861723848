import React from "react";
import QuestionDetailsComponent from "./QuestionDetailsComponent";

class MultipleLineTextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(e) {
    this.props.setAnswer(this.props, e);
  }
  render() {
    const { question, answer } = this.props;
    const { value } = answer;
    const { elementKey, questionContent } = question;
    const { helpText } = questionContent;
    return (
      <div id={`${elementKey}`} className={`question text_multiline`}>
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
        <textarea
          id={`textarea${elementKey}`}
          name="textarea"
          defaultValue={value}
          onBlur={e=>this.handleBlur(e)}
          maxLength="1000"
          placeholder={helpText} />
        </div>
      </div>
    );
  }
}

export default MultipleLineTextComponent;
