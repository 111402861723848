import {
    SINGLELINETEXT,
    MULTIPLELINETEXT,
    DISPLAYMESSAGE,
    NUMBER,
    RANKING,
    RATING,
    RATINGSCALE,
    DATETIME,
    DATE_DROPDOWN,
    DATE_INPUT,
    DROPDOWNLIST,
    NETPROMOTORSCORE,
    MULTIPLECHOICEONE,
    MULTIPLECHOICEMULTIPLE,
    MATRIXCHOICEONE,
    MATRIXCHOICEMULTIPLE,
    MATRIXRANKING,
    MATRIXSINGLELINETEXT,
    EMAIL_SINGLELINETEXT,
    MULTIPLECHOICESINGLELINETEXT,
    MATRIXDROPDOWN,
    PHONE_SINGLELINETEXT
    // PAGE
} from "./TypeValues";
export class AnswerSkip {
    static value = [];
    static keepAnswerState = undefined;
    static clearValue = () => {
        this.value = [];
    }
}

export const setEmptyAnswer = (node, answer) => {
    if (node.type === SINGLELINETEXT ||
        node.type === EMAIL_SINGLELINETEXT ||
        node.type === MULTIPLELINETEXT ||
        node.type === NUMBER ||
        node.type === RANKING ||
        node.type === RATING ||
        node.type === RATINGSCALE ||
        node.type === DATE_INPUT ||
        node.type === DATETIME ||
        node.type === DATE_DROPDOWN ||
        node.type === DROPDOWNLIST ||
        node.type === NETPROMOTORSCORE
    ) {

        answer.value = '';
        answer.error = '';

    } else if (node.type === DISPLAYMESSAGE) {

    } else if (node.type === MULTIPLECHOICEONE) {
        let answer = {};
        if (node.includeOther) {
            answer.isIncludeOther = false;
            answer.IncludeOtherText = "";
            answer.value = [];
            answer.error = "";
        } else {
            answer.value = [];
            answer.error = "";
        }

    } else if (node.type === MULTIPLECHOICESINGLELINETEXT) {
        answer.isIncludeOther = false;
        answer.IncludeOtherText = "";
        answer.value = [];
        answer.error = "";

    } else if (node.type === MULTIPLECHOICEMULTIPLE) {

        if (node.includeOther) {
            answer.isIncludeOther = false;
            answer.IncludeOtherText = "";
            answer.value = [];
            answer.error = "";
        } else {
            answer.value = [];
            answer.error = "";
        }

    } else if (node.type === MATRIXCHOICEONE) {
        answer.value = [];
        answer.error = "";

    } else if (node.type === MATRIXCHOICEMULTIPLE) {
        answer.value = {
            value: [],
            error: ""
        };
        answer.error = "";
    } else if (node.type === MATRIXRANKING) {
        answer.value = {
            value: [],
            error: ""
        };
        answer.error = "";
    } else if (node.type === MATRIXSINGLELINETEXT) {
        answer.value = {
            value: [],
            error: ""
        };
        answer.error = "";
    } else if (node.type === MATRIXDROPDOWN) {
        answer.value = {
            value: [],
            error: ""
        };
        answer.error = "";
    } else if (node.type === PHONE_SINGLELINETEXT) {
        answer.value = "";
        answer.error = "";

    }
    return answer;
}
