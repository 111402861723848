import React from "react";
import parse from 'html-react-parser';
import { isNull, isNullOrUndefined } from "../Utility/index";
import LogoComponent from "./LogoComponent";
import {convertTextToScreen} from "../Utility/ConditionValidator";

class WelcomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.parseContextVariable = this.parseContextVariable.bind(this);
  }
  parseContextVariable(content) {
    const { conduct } = this.props;

    var replacements = {};
    conduct.contextVariables.forEach(function(ctx) {
      replacements[`{${ctx.name}}`] = decodeURIComponent(ctx.value);
    });

    var parseContent = content.replace(/{\w+}/g, function(a) {
      return replacements[a] || a;
    });
    parseContent = parseContent.replace(/\{\w+\}/g, '');

    return parseContent;
  }
  render() {
    const { surveyTitle, welcomeContent } = this.props.conduct.surveyContent;

    return (
      <div className="page_body">
        <LogoComponent conduct={this.props.conduct} />
        {!isNull(surveyTitle) ? (
          <div className="welcome_title"><span className="welcome_title_content">{surveyTitle}</span></div>
        ) : (
          ""
        )}

        {isNullOrUndefined(welcomeContent) ? (
          ""
        ) : (
          <div className="welcome_description">
            {parse(this.parseContextVariable(convertTextToScreen(welcomeContent)))}
          </div>
        )}
      </div>
    );
  }
}
export default WelcomeComponent;
