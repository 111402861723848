import Exp from "../Expression/expression";
import {
    GROUP,
    PAGE,
    QUESTION,
    SINGLELINETEXT,
    EMAIL_SINGLELINETEXT,
    MULTIPLELINETEXT,
    // DISPLAYMESSAGE,
    NUMBER,
    RANKING,
    RATING,
    RATINGSCALE,
    DATETIME,
    DROPDOWNLIST,
    NETPROMOTORSCORE,
    MULTIPLECHOICEONE,
    MULTIPLECHOICEMULTIPLE,
    MATRIXCHOICEONE,
    MATRIXCHOICEMULTIPLE,
    MATRIXRANKING,
    MATRIXSINGLELINETEXT,
    PHONE_SINGLELINETEXT,
    DISPLAYMESSAGE
} from "./TypeValues";
import {
    isNullOrUndefined,
    isArray
} from "./index";
import store from "../store";
import {
    setStackWalkStep,
    setApiUrl,
    setProgress
} from "../Actions/AppAction";
import {
    removeAnswer
} from "../Actions/AnswerAction";
import { AnswerSkip } from './AnswerValidateState';
import moment from "moment";
import debounce from 'lodash/debounce';
export const findQuestionInCurrentNode = (
    data,
    questions,
    answers,
    context
) => {
    let d = data.reduce((acc, v, i, arr) => {
        if (v.type === GROUP) {
            findQuestionInGroup(v, questions, answers, context).filter(node => {
                acc.push(node);
                return true;
            });
        } else if (v.type === PAGE) {
            findQuestionInPage(v, questions, answers, context).filter(node => {
                acc.push(node);
                return true;
            });
        } else if (
            v.type === QUESTION &&
            Exp.run_object_context(v.condition, context)
        ) {
            acc.push(v);
        }
        return acc;
    }, []);
    return d;
};
export const findQuestionInPage = (
    data,
    questions,
    answers,
    context,
    returnTree = false
) => {
    if (!Exp.run_object_context(data.condition, context)) {
        if (returnTree) return null;
        return [];
    }
    var list = [];
    const rootId = data.id;

    function createArrayLine(node, count = 0, parent = "", slefid = data.id) {
        node.children.filter(e => {
            if (PAGE === e.type) {
                throw new Error("ERROR : CANT NOT USE PAGE IN PAGE");
            }

            if (rootId === e.parentID) {
                count++;
            }
            if (e.children.length > 0) {
                //parent or have child
                if (Exp.run_object_context(e.condition, context)) {
                    createArrayLine(e, count, node.id, e.id);
                }
            } else {
                if (Exp.run_object_context(e.condition, context)) {
                    list.push({
                        ...e,
                        haveChild: false,
                        subRoot: count,
                        isSubRoot: rootId === e.parentID
                    });
                }
            }
            return true;
        });
    }
    createArrayLine(data);
    if (returnTree) {
        data.children = list;
        return data;
    }

    return list;
};
export const findQuestionInGroup = (
    data,
    questions,
    answers,
    context,
    returnTree = false
) => {
    if (!Exp.run_object_context(data.condition, context)) {
        if (returnTree) return null;
        return [];
    }
    var list = [];
    const rootId = data.id;

    function createArrayLine(node, count = 0, parent = "", slefid = data.id) {
        node.children.filter(e => {
            if (rootId === e.parentID) {
                count++;
            }
            list.push({
                ...e,
                haveChild: true,
                isSubRoot: rootId === e.parentID,
                subRoot: count
            });
            if (e.children.length > 0) {
                //parent or have child
                if (Exp.run_object_context(e.condition, context)) {
                    createArrayLine(e, count, node.id, e.id);
                }
            } else {
                if (Exp.run_object_context(e.condition, context)) {
                    list.push({
                        ...e,
                        haveChild: false,
                        subRoot: count,
                        isSubRoot: rootId === e.parentID
                    });
                }
            }
            return true;
        });
    }
    createArrayLine(data);
    if (returnTree) {
        data.children = list;
        return data;
    }
    return list;
};
export function surveyFlowArrayLine(data) {
    const rootId = data.id;
    let count = -1;
    let list = [];
    data = {...data,
        subRoot: count,
        layer: 0
    };
    list.push({...data,
        children: []
    });

    function inner(data, layer = 1) {
        data.children = data.children.map(e => {
            let isSubRoot = false;
            if (rootId === e.parentID) {
                isSubRoot = true;
                count++;
            }
            e = {...e,
                subRoot: count,
                layer: layer,
                isSubRoot: isSubRoot
            };
            list.push(e);
            if (e.children.length > 0) {
                inner(e, layer + 1);
            }
            return e;
        });
    }
    inner(data);

    return [...list];
}
export const modifySurveyFlow = data => {
    const rootId = data.id;
    let count = -1;
    let list = [];
    data = {...data,
        subRoot: count,
        layer: 0
    };
    list.push({...data,
        children: []
    });

    function inner(data, layer = 1) {
        data.children = data.children.map(e => {
            let isSubRoot = false;
            if (rootId === e.parentID) {
                isSubRoot = true;
                count++;
            }
            e = {...e,
                subRoot: count,
                layer: layer,
                isSubRoot: isSubRoot
            };
            if (!(data.type === PAGE && e.type === QUESTION) &&
                !(data.type === PAGE && e.type === GROUP)
            ) {
                list.push(e);
            }
            if (e.children.length > 0) {
                if (!(data.type === PAGE && e.type === GROUP)) {
                    inner(e, layer + 1);
                }
            }
            return e;
        });
    }
    inner(data);
    return [...list];
};
export const getAllSuveyFlow = () => {
    return [...store.getState().conductReducer.surveyFlowArrayLine];
};
export const getCopySurveyFlow = () => {
    return [...store.getState().conductReducer.surveyFlowLog];
};
export const getStackAction = () => {
    return [...store.getState().appReducer.stackActionLog];
};
export const getLengthStackAction = l => {
    const length = [...store.getState().appReducer.stackActionLog].length;
    return length === 0 ? 0 : length - l;
};
export const getprogress = () => {
  return store.getState().appReducer;
}
export const setStackAction = (isPop = false, value) => {
    store.dispatch(setStackWalkStep(isPop, value));
};
export const removeAnswerAction = (key) => {
    if (store.getState().answerReducer.data.hasOwnProperty(key)) {
        store.dispatch(removeAnswer(key));
    }

}
export const getNodeWhenSkipWelcome = (
    surveyFlow,
    questions,
    answers,
    contextVariables
) => {
    const context = Exp.survey_conduct_context(
        questions,
        answers,
        contextVariables
    );
    if (!surveyFlow ||
        !Exp.run_object_context(surveyFlow[0].condition, context)
    ) {
        return null;
    }
    let count = 1;
    let data = surveyFlow[count];
    while (data && !Exp.run_object_context(data.condition, context)) {
        count++;
        data = surveyFlow[count];
    }
    return isNullOrUndefined(data) ? null : data;
};
export const clearValueAnswerState = debounce((valuesObj, keyObj, context) => {
    // check clear value if not just right way by surveyflow
    let i = 1;
    let mockCh = valuesObj[i];
    while (!isNullOrUndefined(mockCh)) {
        let indexP = keyObj.indexOf(mockCh.parentID);
        const mockParent = valuesObj[indexP];
        mockCh = valuesObj[i];
        if (!Exp.run_object_context(mockParent.condition, context) && mockCh.elementKey) {
            const elementKey = mockCh.elementKey;
            setTimeout(() => {
                removeAnswerAction(elementKey);
            }, 5);
        } else if (!Exp.run_object_context(mockCh.condition, context) && mockCh.elementKey) {
            const elementKey = mockCh.elementKey;
            setTimeout(() => {
                removeAnswerAction(elementKey);
            }, 5);
        }
        i++;
        mockCh = valuesObj[i];
    }
}, 10);
export const validateConditionToRoot = (node, context) => {
    if (isNullOrUndefined(node)) return false;
    let list = [];
    list.push(node.condition);
    let valuesObj = getAllSuveyFlow();
    let keyObj = valuesObj.map(e => e.id);
    while (!isNullOrUndefined(node)) {
        let index = keyObj.indexOf(node.parentID);
        if (index >= 0) {
            node = valuesObj[index];
            list.push(node.condition);
        } else {
            node = null;
        }
    }
    if (list.length > 0 && AnswerSkip.keepAnswerState === false) {
        clearValueAnswerState(valuesObj, keyObj, context);

    }
    return !list
        .reduce((acc, v, i, arr) => {
            const ch = Exp.run_object_context(v, context);
            // check from validate surveyflow is undefined
            if (isNullOrUndefined(ch) || ch === false) {
                acc.push(false);
            } else {
                acc.push(ch);
            }
            return acc;
        }, [])
        .some(t => {
            return t === false;
        });
};
export const setPathForApiCall = (url = "/") => {
    store.dispatch(setApiUrl(url));
}
export const getPathForApiCall = () => {
    return store.getState().appReducer.pathForApiCall;
}
export const countProgressByPage = (count = 0) => {
  const flow = store.getState().conductReducer.surveyFlowArrayLine;
 // const currentNode = store.getState().conductReducer.currentNode;
  const context = getContext();
  const k = [];
  let countIndex = 0;
  count = store.getState().appReducer.complete.doPass + count;
  count = count < 0 ? 0 : count;
  countIndex++;
    flow.filter(r => {
      if (r.type === PAGE && checkCanElementCanRender(context, r, flow, k)) {
            countIndex++;
      }
      return r;
    });
    let percent =  (count / countIndex) *100;
    percent = percent > 100 ? 100 : percent;
    store.dispatch(setProgress({percent: isNaN(percent)? 0 : percent , all: countIndex, doPass: isNaN(count)?0:count}));
}
export const removeAnswerValueAny = () => {
    const context = getContext();
    const flow = store.getState().conductReducer.surveyFlowArrayLine;
   // const questions = store.getState().conductReducer.questions;
    const removeElementKey = [];
    // let countQuestions = 0;
    // let answerCount = 0;
    flow.filter(r => {
        const k = [];
        if (r.type === QUESTION) {
            if (!checkCanElementCanRender(context, r, flow, k)) {
                removeElementKey.push(r.elementKey);
                store.getState().appReducer.notsave = store.getState().appReducer.notsave.filter(x => {
                    return x.elementKey !== r.elementKey;
                });
                delete store.getState().answerReducer.data[r.elementKey];
            }
            // else {
            //     const question = questions.find(t => t.elementKey === r.elementKey);
            //     if (question && question.type !== DISPLAYMESSAGE) {
            //           countQuestions++;
            //           if (checkAnswerHasValue(r.elementKey, question.type)) {
            //             answerCount++;
            //           }
            //     }

            // }
        }
        return r;
    });
    // const cur = Object.keys(store.getState().answerReducer.data).length;
    // count data
    // if (countQuestions === 0 | answerCount === 0) {
    //     store.dispatch(setProgress(0));
    // } else if (countQuestions > 0 | answerCount > 0) {
    //     store.dispatch(setProgress((100 * answerCount) / countQuestions));
    // }
    return removeElementKey;
}
// export const findProgressFlow = (context) => {
//     const flow = store.getState().conductReducer.surveyFlowArrayLine;
//     const elementKey = [];
//     flow.filter(r => {
//         if (Exp.run_object_context(r.condition, context) && r.type === QUESTION) {
//             elementKey.push(r.elementKey);
//         }
//         return r;
//     });
//     return { key: elementKey };
// }
export const checkCanElementCanRender = (context, node, flow, removeListFlow) => {
    if (node.parentID === 0) {
        return true;
    }
    if (Exp.run_object_context(node.condition, context)) {
        node = flow.find(x => x.id === node.parentID);
        return checkCanElementCanRender(context, node, flow, removeListFlow);

    }
    removeListFlow.push(node.elementKey);
    return false

}
export const getContext = () => {
    const questions = store.getState().conductReducer.questions;
    const answers = store.getState().answerReducer.data;
    const contextVariables = store.getState().conductReducer.contextVariables;
    return Exp.survey_conduct_context(
        questions,
        answers,
        contextVariables
    );
}

export const checkAnswerHasValue = (elementKey, elementType) => {
    const answers = store.getState().answerReducer.data;
    const answer = answers[elementKey];
    if (answer) {

        if (SINGLELINETEXT === elementType ||
            EMAIL_SINGLELINETEXT === elementType ||
            PHONE_SINGLELINETEXT === elementType ||
            NUMBER === elementType ||
            NETPROMOTORSCORE === elementType ||
            DROPDOWNLIST === elementType ||
            RATINGSCALE === elementType ||
            DATETIME === elementType ||
            MULTIPLELINETEXT === elementType ||
            RATING === elementType) {
            if (isNullOrUndefined(answer.value) || answer.value === '') {
                return false;
            }
            return true;
        } else if (MATRIXSINGLELINETEXT === elementType) {
          if (isArray(answer.value)) {
             return answer.value.some( e=> !isNullOrUndefined(e.value) && e.value !== '');
          }
          return true;
        } else if (RANKING === elementType) {
          return true;
        } else if (MULTIPLECHOICEONE === elementType ||
            MULTIPLECHOICEMULTIPLE === elementType
        ) {
            if (answer.isIncludeOther) {
                if (isNullOrUndefined(answer.IncludeOtherText) || answer.IncludeOtherText === '') {
                    return false;
                }
                return true;
            }
            if (isArray(answer.value)) {
                return answer.value.some(e => e.checkState === true);
            }
            return true;
        } else if (MATRIXRANKING === elementType || MATRIXCHOICEONE === elementType || MATRIXCHOICEMULTIPLE === elementType) {
          if (isArray(answer.value)) {
            return answer.value.some(e => e.checkState === true);
          }
        }

    }
    return false;
}
export const findSystaxBucket = (text, questions, answers, conduct) => {
    // console.log("ASDAS",text);
    //  console.log(text);
  let convertText = null;
  let first = text.indexOf('[[');
  let last = text.indexOf(']]');
  if (first >= 0 && last >= 0 && last > first) {
      last = last - first - 2;
      first = first + 2;
      convertText = text.substr(first, last);
      if (!isNullOrUndefined(convertText)) {
        const condition = convertText.split(':');
        const replaceText = getTextByQuestionType(questions, answers, condition, conduct);
       console.log(replaceText);
          text = replaceAll(text, convertText, replaceText);
          return findSystaxBucket(text, questions, answers, conduct);
      }
  }
  return text;
}

export const evaluateExpression = (text) => {
    let expressionText = null;
    let first = text.indexOf('[');
    let last = text.indexOf(']');
    if (first >= 0 && last >= 0 && last > first) {
        last = last - first - 1;
        first = first + 1;
        expressionText = text.substr(first, last);
        if (!isNullOrUndefined(expressionText)) {          
          if (expressionText.startsWith('CheckEmpty')) {
            // CheckEmpty
            let start = expressionText.indexOf('(');
            let end = expressionText.indexOf(')');            

            let expValue = expressionText.substr(start + 1, (end - start - 1));
            let replaceText = '';
            if (expValue.length === 0) {
                replaceText = expressionText.match(/{([^}]*)}/)[1];
            } else {
                replaceText = '';
            }
            text = text.replace(`[${expressionText}]`, replaceText);
            return evaluateExpression(text);
          } else if (expressionText.startsWith('CheckNotEmpty')) {
            // CheckNotEmpty
            let start = expressionText.indexOf('(');
            let end = expressionText.indexOf(')');            

            let expValue = expressionText.substr(start + 1, (end - start - 1));
            let replaceText = '';
            if (expValue.length > 0) {
                replaceText = expressionText.match(/{([^}]*)}/)[1];
            } else {
                replaceText = '';
            }
            text = text.replace(`[${expressionText}]`, replaceText);
            return evaluateExpression(text);
          }
        }
    }
    return text;
}

export const convertTextToScreen = (text) => {
   
  const questions = store.getState().conductReducer.questions;
  const answers = store.getState().answerReducer.data;
  const conduct = store.getState().conductReducer;
  return findSystaxBucket(text, questions, answers, conduct);
}

export const replaceAll = ( str, find, replaceText)=> {
  return str.replace( new RegExp(`\\[\\[${find}\\]\\]`, 'g'), replaceText);
  
}
export const getTextByQuestionType = (questions, answers, condition, conduct) => {
  let returnText = '';
  const findWhere = condition[0];
  const questionName = condition[1];
  const question = questions.find(x => x.name === questionName);
  if (findWhere === 'datetime') {
      const format = condition[1];
      moment.locale('th') ;
      return moment(conduct.renderText.datetime).format(format);
  } else if (conduct.renderText.hasOwnProperty(findWhere)) {
    //console.log("A")
      return conduct.renderText[findWhere];
  } else if (!isNullOrUndefined(question)){
      const answer = answers[question.elementKey];
      if (findWhere === 'answer' && !isNullOrUndefined(answer)) {
        if (question.type === DROPDOWNLIST) {
            const dataObject = question.choices.find(x => x.elementKey ===  answer.value);
            if (!isNullOrUndefined(dataObject) && dataObject !== '') {
              returnText = dataObject.choiceText;
            }
        }
        else  if (question.type === MULTIPLECHOICEMULTIPLE || question.type === MULTIPLECHOICEONE) {
          const dataObject = [];
          answer.value.forEach(x => {
            if (x.checkState === true) {
                dataObject.push(question.choices.find(o => o.elementKey === x.elementKey));
            }
          });

          if (dataObject.length > 0) {
            if (answer.isIncludeOther) {
              returnText = dataObject.map(x=>x.choiceText).join(' , ')
            } else {
              returnText = dataObject.map(x=>x.choiceText).join(' , ') + ` ${answer.IncludeOtherText}`;
            }

          }
        } else if (question.type === RANKING) {
          const dataObject = [];
          answer.value.forEach(x => {
            dataObject.push(question.choices.find(o => o.elementKey === x));
          });
          if (dataObject.length > 0) {
            returnText = dataObject.map(x=>x.choiceText).join(' , ');
          }
        }
        else  if (question.type === DATETIME) {
              const format = condition[2];
              moment.locale('th') ;
              returnText =  moment(answer.value).format(format);
        }
        else  if (question.type === RATING && answer.value !== '') {
          if (condition[2]) {
            returnText = (condition[2] * 1) + answer.value * 1;
          } else {
            returnText = question.ratingQuestionContent[answer.value].ratingLabelText;
          }
        }
        else if (SINGLELINETEXT === question.type ||
          EMAIL_SINGLELINETEXT === question.type ||
          PHONE_SINGLELINETEXT === question.type ||
          NUMBER === question.type ||
          MULTIPLELINETEXT === question.type) {
            returnText = answer.value;
          }
        else if (
          (NETPROMOTORSCORE === question.type ||
          RATINGSCALE === question.type) && answer.value !== '' ) {
          if (condition[2]) {
            returnText = (condition[2] * 1) + answer.value * 1;

          } else {
            returnText = answer.value;
          }

        }
        else if (MATRIXRANKING === question.type || MATRIXCHOICEONE === question.type || MATRIXCHOICEMULTIPLE === question.type) {
          const dataObject = [];
          answer.value.forEach(x =>{
            if (x.checkState === true) {
                const choice  = question.matrixChoices.find(p=> p.elementKey === x.choiceElementKey).choiceText;
                const item = question.matrixQuestionItems.find(p=> p.elementKey === x.questionElementKey).questionItemText;
                dataObject.push(`${item} ${choice}`);
            }
          });
          returnText = dataObject.join(' ,');
        } else if (MATRIXSINGLELINETEXT === question.type ) {
          const dataObject = [];
          answer.value.forEach(x =>{
            if (!isNullOrUndefined(x.value) && x.value !== '') {
                const choice  = question.matrixChoices.find(p=> p.elementKey === x.choiceElementKey).choiceText;
                const item = question.matrixQuestionItems.find(p=> p.elementKey === x.questionElementKey).questionItemText;
                dataObject.push(`${item} ${choice} ${x.value}`);
            }
          });
          returnText = dataObject.join(' ,');
        }

      } else if (findWhere === 'question') {
        if (question.type === DISPLAYMESSAGE) {
          returnText = question.displayMessageContent.headerText;
        } else {
          returnText = question.questionContent.questionText;
        }

      } else if (findWhere === 'choice') {
        const choice = condition[2];
        let dataObject = '';
        if ((question.type === MULTIPLECHOICEMULTIPLE || question.type === MULTIPLECHOICEONE|| question.type === DROPDOWNLIST|| question.type === RANKING)) {
              question.choices.forEach( ch => {
                if (ch.name ===  choice) {
                  dataObject = ch.choiceText;
                }
              });
        }

        returnText =  dataObject;
      }
  }

  return returnText;
}
export const findRecursiveData = (flow, arr, keep)=> {
      flow.children.forEach(x => {
          if (x.type === QUESTION) {
            const index = arr.indexOf(x.elementKey);
            if (index >= 0) {
                  keep[index] = (x);
            }
          } else {
            return findRecursiveData(x, arr, keep);
          }
      });

}
export const replaceRecursiveData = (flow, target, arr)=> {
  flow.children.forEach((x, i) => {
      if (x.type === QUESTION) {
        const index = target.indexOf(x.elementKey);
        if (index >= 0) {
          arr[index].parentID = flow.id;
          flow.children[i] = arr[index];
        }
      } else {
        return replaceRecursiveData(x, target,arr);
      }
  });

}

