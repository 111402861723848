import { unAuthorized } from "../Utility";
import { urlDefalutApi, UNAUTHORIZED_CODE, keyAes } from "../Utility/TypeValues";
import store from "../store";
import { setAnswerNotSave } from '../Actions/AppAction';
import AES from "crypto-js/aes";
import { isNullOrUndefined } from "../Utility/index";
export const aesEncrypted = (dataJson) => {
  dataJson = JSON.stringify(dataJson);
  return AES.encrypt(dataJson, keyAes);
}
export const detectUrlGetConductByBrower = url => {
  var isIE11 =  document.documentMode <= 11;
  if (isIE11) {
    return url + "?date=" + new Date().getTime();
  }
  return url;
}
export const getConductData = (param, token) => {
  const url = detectUrlGetConductByBrower(`${urlDefalutApi}/${param}`);
  const header = new Headers({
    Param: window.location.search.replace('?', ''),
    Token: token
  });
  const myInit = {
    method: "GET",
    headers: header,
    mode: "cors",
    cache: "default"
  };
  return fetch(url, myInit).then(function (response) {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getConductQuestion = id => {
  const url = `${urlDefalutApi}/getquestions/${id}`;
  const myHeaders = new Headers({
    Param: window.location.search.replace('?', ''),
  });
  const myInit = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
    cache: "default"
  };

  return fetch(url, myInit).then(function (response) {
    if (response.ok) {
      return response.json();
    }
  }).catch(error => console.error('Error:', error));
};

export const saveStartConduct = (accessToken, conductID, token) => {
  const url = `${urlDefalutApi}/conduct/${conductID}/start`;
  const headers = new Headers({
    Param: window.location.search.replace('?', ''),
    // Authorization: `Bearer ${accessToken}`
  });
  const init = {
    method: "POST",
    headers: headers,
    mode: "cors",
    cache: "default"
  };

  return fetch(url, init).then(function (response) {
    if (response.ok) {
      return response.json();
    } else if (response.status === UNAUTHORIZED_CODE) {
      unAuthorized(response);
    }
  }).catch(error => { });
};

export const saveFinishConduct = (accessToken, conductID, token, callBack) => {
  let isError = false;
  const url = `${urlDefalutApi}/conduct/${conductID}/finish`;
  const headers = new Headers({
    Param: window.location.search.replace('?', ''),
    // Authorization: `Bearer ${accessToken}`
  });
  const init = {
    method: "POST",
    headers: headers,
    mode: "cors",
    cache: "default"
  };

  return fetch(url, init).then(function (response) {
    if (response.ok) {
      callBack(true);
      return response.json();
    } else {
      callBack(false);
      isError = true;
    }

  }).catch(error => {
    // console.log('saveFinishConduct');
    if (isError === false) {
      callBack(false);
    }
  });
};
const fetchAnswerRespnse = (accessToken, token, responseAnswers, callBack) => {
  const conduct = Object.assign({}, store.getState().conductReducer);
  let isError = false;
  const url = `${urlDefalutApi}/response/`;
  fetch(url, {
    method: 'POST',
    body: conduct.aes ? aesEncrypted(JSON.stringify(responseAnswers)) : JSON.stringify(responseAnswers),
    mode: "cors",
    headers: new Headers({
      'Content-Type': 'application/json',
      Token: isNullOrUndefined(token) ? '' : token,
      Param: window.location.search.replace('?', ''),
      // Authorization: `Bearer ${accessToken}`
    })
  }).then(function (response) {
    if (response.ok) {
      const listNames = [];
      responseAnswers.forEach(o => {
        listNames.push(o.elementKey);
      });
      let logAnswers = Object.assign([], store.getState().appReducer.notsave);
      logAnswers = logAnswers.filter(f => {
        return listNames.indexOf(f.elementKey) < 0;
      });
      store.dispatch(setAnswerNotSave(logAnswers));
      callBack(true);
      return response.json();
    } else {
      callBack(false);
      isError = true;
    }
    return response;
  })
    .catch(error => {
      if (isError === false) {
        callBack(false);
      }
    });
}
export const saveSurveyResponse = (accessToken, token, responseAnswers, callBack) => {
  let logAnswers = Object.assign([], store.getState().appReducer.notsave);
  logAnswers = [...logAnswers, ...responseAnswers];
  const last = [];
  const listNames = [];
  logAnswers.forEach(e => {
    const answers = logAnswers.filter(d => {
      return d.elementKey === e.elementKey
    });
    if ((answers.length > 1 || answers.length === 1)) {
      const a = answers[answers.length - 1];
      if (listNames.indexOf(a.elementKey) < 0) {
        listNames.push(a.elementKey);
        last.push(a);
      }
    }
  });
  store.dispatch(setAnswerNotSave(last));
  if (last.length > 0) {
    fetchAnswerRespnse(accessToken, token, last, callBack);
  } else {
    callBack(true);
  }
};
