import React from "react";
import parse from 'html-react-parser';
import QuestionDetailsComponent from "./QuestionDetailsComponent";
import { isNullOrUndefined, cloneChoices } from "../Utility/index";

class MultipleChoiceMultipleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  //  this.textboxChange = _.debounce(this.textboxChange.bind(this), 100);
    this.isChecked = this.isChecked.bind(this);
    this.getAppendText = this.getAppendText.bind(this);
  }

  currentAnswerValue() {
    /* define answer model */
    const { question } = this.props;
    let choice_values = question.choices.map(ch => ({
      elementKey: ch.elementKey,
      choiceText: this.getAppendText(ch),
      checkState: this.isChecked(ch)
    }));
    let new_value = null;
    if (question.includeOther) {
      new_value = {
        isIncludeOther: this.isChecked(question),
        IncludeOtherText: this.getAppendText(question),
        value: choice_values
      };
    } else {
      new_value = choice_values;
    }
    return new_value;
  }

  handleClick(event, choice) {
    const { question, answer } = this.props;
    let new_value = this.currentAnswerValue();
    if (question.includeOther) {
      if (answer.value.length === 0) {
        if (question.elementKey === choice.elementKey) {
          new_value.isIncludeOther = event.target.checked;
        }
        new_value.value = new_value.value.map(e => {
          if (choice.elementKey === e.elementKey) {
            e.checkState = event.target.checked;
          }

          return e;
        });
      } else {
        if (question.elementKey === choice.elementKey) {
          new_value.isIncludeOther = event.target.checked;
        }
        new_value.value = new_value.value.map(e => {
          if (choice.elementKey === e.elementKey) {
            e.checkState = answer.value.reduce((acc, v, i, arr) => {
              if (v.elementKey === e.elementKey) {
                acc = event.target.checked;
              }
              return acc;
            }, true);
          }

          return e;
        });
      }
    } else {
      if (answer.value.length === 0) {
        new_value = new_value.map(e => {
          if (choice.elementKey === e.elementKey) {
            e.checkState = event.target.checked;
          }

          return e;
        });
      } else {
        new_value = new_value.map(e => {
          if (choice.elementKey === e.elementKey) {
            e.checkState = answer.value.reduce((acc, v, i, arr) => {
              if (v.elementKey === e.elementKey) {
                acc = event.target.checked;
              }
              return acc;
            }, true);
          }

          return e;
        });
      }
    }
    new_value = this.unselectChoices(new_value, choice);
    this.props.setAnswer(this.props, new_value);
  }
  unselectChoices(new_value, choice) {
    const { question } = this.props;
    //set data by includeOther value
    let logVal = new_value;
    if (question.includeOther === true) {
      logVal = new_value.value;
    }
    // dom include other
    const domIncludeOther = document.querySelector(
      `#choice${question.elementKey}`
    );
    if (
      question.includeOther === true &&
      question.unselectAllChoices === true &&
      new_value.isIncludeOther === true
    ) {
      new_value.value = new_value.value.map(e => {
        if (e.elementKey !== choice.elementKey) {
          e.checkState = false;
          document.querySelector(`#choice${e.elementKey}`).checked = false;
        } else {
          document.querySelector(
            `#choice${question.elementKey}`
          ).checked = false;
          new_value.isIncludeOther = false;
        }
        return e;
      });
    } else if (
      question.includeOther === true &&
      question.unselectAllChoices === false &&
      new_value.isIncludeOther === true &&
      !logVal.some(e => e.elementKey === choice.elementKey)
    ) {
      new_value.isIncludeOther = true;
      question.choices.forEach(f => {
        if (f.unselectAllChoices === true && document.querySelector(`#choice${f.elementKey}`).checked === true) {
            document.querySelector(
              `#choice${f.elementKey}`
            ).checked = false;
        }
      });
    }

    if (
      logVal.some(
        e => e.elementKey === choice.elementKey && e.checkState === true
      )
    ) {
      let log = {};
      if (choice.unselectAllChoices === true) {
        question.choices.forEach(element => {
          if (choice.elementKey !== element.elementKey) {
            document.querySelector(
              `#choice${element.elementKey}`
            ).checked = false;
            log = { [element.elementKey]: false, ...log };
          } else {
            log = { [element.elementKey]: true, ...log };
          }
        });
      } else {
        question.choices.forEach(element => {
          if (
            choice.elementKey !== element.elementKey &&
            element.unselectAllChoices === true
          ) {
            document.querySelector(
              `#choice${element.elementKey}`
            ).checked = false;
            log = { [element.elementKey]: false, ...log };
          }
        });
      }

      if (
        !isNullOrUndefined(domIncludeOther) &&
        choice.unselectAllChoices === true
      ) {
        domIncludeOther.checked = false;
      }
      if (question.includeOther === true) {
        new_value.value = new_value.value.map(g => {
          if (!isNullOrUndefined(log[g.elementKey]))
            g.checkState = log[g.elementKey];
          return g;
        });
      } else {
        new_value = new_value.map(g => {
          if (!isNullOrUndefined(log[g.elementKey]))
            g.checkState = log[g.elementKey];
          return g;
        });
      }
      new_value.isIncludeOther = isNullOrUndefined(domIncludeOther)
        ? false
        : domIncludeOther.checked;
    }
      // clear answer include
    if (new_value.isIncludeOther === false) {
      if (document.getElementById(`choicetext${question.elementKey}`)) {
        new_value['IncludeOtherText'] = "";
        document.getElementById(`choicetext${question.elementKey}`).value = '';
      }
    }
    return new_value;
  }
  textboxChange(element, choice) {
    const { question, answer } = this.props;
    let new_value = this.currentAnswerValue();
    if (question.includeOther) {
      if (question.elementKey === choice.elementKey) {
        new_value.IncludeOtherText = element.target.value;
      }
      new_value.isIncludeOther = answer.isIncludeOther;
      new_value.value = new_value.value.map(e => {
        if (e.elementKey === choice.elementKey) {
          e.choiceText = element.target.value;
        }

        return e;
      });
    } else {
      new_value = new_value.map(e => {
        if (e.elementKey === choice.elementKey) {
          e.choiceText = element.target.value;
        }

        return e;
      });
    }

    this.props.setAnswer(this.props, new_value);
  }

  getAppendText(choice) {
    const { value } = this.props.answer;
    if (choice.elementKey === this.props.question.elementKey) {
      return this.props.answer.IncludeOtherText;
    }
    if (!Array.isArray(value)) {
      return "";
    } else {
      const ch = value.find(v => v.elementKey === choice.elementKey);
      if (!ch) {
        return "";
      } else {
        return isNullOrUndefined(ch.choiceText) ? "" : ch.choiceText;
      }
    }
  }
  isChecked(choice) {
    const { answer, question } = this.props;
    if (choice.elementKey === question.elementKey) {
      return answer.isIncludeOther;
    }

    if (answer.value.length === 0) {
      return false;
    } else {
      return answer.value.reduce((acc, v, i, arr) => {
        if (v.elementKey === choice.elementKey) {
          acc = v.checkState;
        }
        return acc;
      }, false);
    }
  }

  render() {
    const { question } = this.props;
    const { multipleChoiceDisplayLayout } = question;
    let choicesCopy = cloneChoices(this.props);
    const choice_group_size =
      multipleChoiceDisplayLayout === "ONE_COLUMN"
        ? 1
        : multipleChoiceDisplayLayout === "TWO_COLUMN" ? 2 : 3;

    const choice_groups = choicesCopy.reduce(
      (acc, ch, i) => {
        let group = acc.pop();
        if (group.length === choice_group_size) {
          acc.push(group);
          group = [];
        }
        group.push(ch);
        acc.push(group);
        return acc;
      },
      [[]]
    );

    return (
      <div
        id={`${question.elementKey}`}
        className={`question multiple_choice_multiple_answer`}
      >
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <div className="choices">
            {choice_groups.map(g => (
              <div
                key={g.reduce((acc, v, i, arr) => {
                  acc += v.elementKey;
                  return acc;
                }, "choice_group")}
                className="choice_group"
              >
                {g.map(ch => (
                  <div key={ch.elementKey} className="choice">
                    <input
                      type="checkbox"
                      id={`choice${ch.elementKey}`}
                      onChange={e => {
                        this.handleClick(e, ch);
                      }}
                      defaultChecked={this.isChecked(ch)}
                    />
                    <label>
                      <label
                        htmlFor={`choice${ch.elementKey}`}
                        className="checkmark"
                      />
                      {ch.choiceText ? (
                        <label
                          htmlFor={`choice${ch.elementKey}`}
                          className="choice_content"
                        >
                          {parse(ch.choiceText)}
                        </label>
                      ) : null}
                      {ch.appendText ? (
                        <div className="append_text_wrapper">
                          <textarea
                            className="append_text"
                            id={`choicetext${ch.elementKey}`}
                            onBlur={e => {
                              e.persist();
                              e.target.value = e.target.value.trim();
                              this.textboxChange(e, ch);
                            }}
                            defaultValue={this.getAppendText(ch)}
                           ></textarea>
                        </div>
                      ) : null}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleChoiceMultipleComponent;
