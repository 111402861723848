import React, { Component } from 'react'
import { WELCOME } from "../Utility/TypeValues";

export default class ProgressComponent extends Component {
  render() {
    if (!(this.props.conduct.node !== WELCOME )) {
      return null;
    }
    const round = [];
    for (let i = 0; i < this.props.complete.all * 1; i++) {
      round.push(i);
    }
    const calWidth = (this.props.complete.all > 0 ? 100 /this.props.complete.all : 0) + '%';
    return (
      <div className="progress-bar">
      {round.map(x => <span style={{width: calWidth}} key={'progressitem' + x} className={this.props.complete.doPass * 1  > x ? 'progress-bar-status hasprogresscolor' : 'progress-bar-status noprogresscolor'}></span>)}
      </div>
    )
  }
}
