import React, { Component } from "react";
import { connect } from "react-redux";
import NodeContainer from "./NodeContainer";
import ButtonContainer from "./ButtonContainer";
import { NOTFOUND, ERROR_500, LOADING } from "../Utility/TypeValues";
import Exp from "../Expression/expression";
import LanguageComponent from "../Components/LanguageComponent";
import { isArray } from "../Utility/index";
import {
  validateConditionToRoot
} from "../Utility/ConditionValidator";
import ProgressComponent from '../Components/ProgressComponent';
class PagesContainer extends Component {
  render() {
    const { conduct, answers } = this.props;
    if (this.props.nodes) {
      let context = Exp.survey_conduct_context(conduct.questions, answers, conduct.contextVariables);

      if (!validateConditionToRoot(this.props.nodes, context)) {
        return "";
      }
    }
    return (
      <div className={`page`} id={`${this.props.nodes.elementKey?this.props.nodes.elementKey:""}`}>
        <ProgressComponent conduct={this.props.conduct} complete={this.props.complete}/>
        <LanguageComponent
          conduct={this.props.conduct}
        />
        {!isArray(this.props.nodes.title)  ? (
          ""
        ) : (
            <div className="page_title">
              <span className="page_title_content">
                {
                  this.props.nodes.title.filter(r => {
                    return r.languageID === conduct.currentLanguageID;
                  })[0].text
                }
              </span>
            </div>
          )}

        {isArray(this.props.nodes.children) ? (
          <div className={"page_body"}>
            <NodeContainer
              key={"pagenode"+this.props.nodes.elementKey}
              nodes={this.props.nodes.children}
            />
          </div>
        ) : (
            ""
          )}

        {[LOADING, ERROR_500, NOTFOUND].indexOf(conduct.node) < 0 ? (
          <ButtonContainer
            key={"pagebtn"+this.props.nodes.elementKey}
            nodes={this.props.nodes.children}
            buttonNext={
              isArray(this.props.nodes.buttonNext)
                ? this.props.nodes.buttonNext.filter(r => {
                  return r.languageID === conduct.currentLanguageID;
                })[0].buttonNext
                : conduct.surveyContent.buttonNext
            }
            buttonPrevious={
              isArray(this.props.nodes.buttonPrevious)
                ? this.props.nodes.buttonPrevious.filter(r => {
                  return r.languageID === conduct.currentLanguageID;
                })[0].buttonPrevious
                : conduct.surveyContent.buttonPrevious
            }
          />
        ) : (
            ""
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data,
    complete: state.appReducer.complete
  };
};

export default connect(mapStateToProps)(PagesContainer);
