import {isNullOrUndefined, isNumber, isArray} from "../Utility/index";
import { ActionSubscriptionService } from '../Services/ActionSubscriptionService';
import debounce from 'lodash/debounce';
import {
  RANKING,
  MULTIPLECHOICEONE,
  MULTIPLECHOICEMULTIPLE,
  MATRIXCHOICEONE,
  MATRIXCHOICEMULTIPLE,
  MATRIXRANKING,
  MATRIXSINGLELINETEXT,
  MULTIPLECHOICESINGLELINETEXT,
  MATRIXDROPDOWN,
  RATING,
  NUMBER,
  PHONE_SINGLELINETEXT,
  EMAIL_SINGLELINETEXT,
  NETPROMOTORSCORE,
  RATINGSCALE,
  DISPLAYMESSAGE,
  PAGE
} from "./TypeValues";
import store from "../store";
export const checkRequire = (focusRequire, mandatory) => {
    if (!focusRequire) {
      return true;
    }
    return mandatory;
}
export const validateRequireAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && value.trim() === "") {
    return false;
  }
  if (!isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }
  return true;
};

export const validateRequireNpsAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && !isNumber(value)) {
    return false;
  }
  if (!isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }

  return true;
};
export const validateRequireRatingScaleAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && value === "") {
    return false;
  }
  if (!isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }

  return true;
};
export const validateRequireRatingAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && !isNumber(value) && value.trim() === "") {
    return false;
  }
  if (!isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
   }

  return true;
};
export const validateRequireEmailAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && value.trim() === "") {
    return false;
  }
  if (checkRequire(focusRequire, question.mandatory) === false && value.trim() !== "" && !isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }
  if (checkRequire(focusRequire, question.mandatory) && !isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }
  return true;
};
export const validateRequirePhoneAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && value.trim() === "") {
    return false;
  }
  if (checkRequire(focusRequire, question.mandatory) === false && value.trim() !== "" && !isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }
  if (checkRequire(focusRequire, question.mandatory) && !isNullOrUndefined(question.verifyRegularExpression) && question.verifyRegularExpression !== "" && !isNullOrUndefined(value)) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      return false;
    }
  }
  return true;
};
export const validateNumberRequireAuto = (value, question, focusRequire) => {
  value = Number.parseFloat(value);
  if (checkRequire(focusRequire, question.mandatory) === false && isNaN(value)) {
    return true;
  }
  if (checkRequire(focusRequire, question.mandatory) && isNaN(value)) {
    return false;
  }
  if (!isNaN(value) && question.maximumValue < value) {
    return false;
  } else if (!isNaN(value) && question.minimumValue > value) {
    return false;
  }
  return true;
};
export const validateRankingRequireAuto = (value, question, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) && !isNumber(value) && isArray(value) && value.length === 0) {
    return false;
  }
  return true;
};
export const validateRequireSetValueArrayMatrixRankingAuto = ( question, value, focusRequire) => {
  let choiceElementKeys = [];
  let logValue = [];
  if (isArray(value)) {
    if (question.matrixChoices.length === 0 ||  question.matrixQuestionItems.length === 0) {
      return true;
    } else if (question.matrixChoices.length === question.matrixQuestionItems.length) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.choiceElementKey) < 0) {
          choiceElementKeys.push(v.choiceElementKey);

          let data = arr.some((value, index) => {
            return (value.choiceElementKey === v.choiceElementKey && value.checkState);
          });
          acc.push(data);
        }
        return acc;
      }, []);
    } else if (question.matrixChoices.length > question.matrixQuestionItems.length) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.questionElementKey) < 0) {
          choiceElementKeys.push(v.questionElementKey);

          let data = arr.some((value, index) => {
            return (value.questionElementKey === v.questionElementKey && value.checkState);
          });
          acc.push(data);
        }
        return acc;
      }, []);

    } else if (question.matrixChoices.length < question.matrixQuestionItems.length) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.choiceElementKey) < 0) {
          choiceElementKeys.push(v.choiceElementKey);

          let data = arr.some((value, index) => {
            return (value.choiceElementKey === v.choiceElementKey && value.checkState);
          });
          acc.push(data);
        }
        return acc;
      }, []);
    }

  }
  if (checkRequire(focusRequire, question.mandatory) && (logValue.indexOf(false) >= 0 || logValue.length === 0)) {
    return false;
  }
  return true;
};
export const validateRequireSetValueArrayMatrixAuto = (question, value, singleLine = false, focusRequire) => {
  let objectElememtKey = [];
  let logValue = [];
  if (Array.isArray(value)) {
    logValue = value.reduce((acc, v, i, arr) => {
      if (objectElememtKey.indexOf(v.questionElementKey) < 0) {
        objectElememtKey.push(v.questionElementKey);

        let data = arr.some((value, index) => {
          if (singleLine) {
            return (value.questionElementKey === v.questionElementKey && !isNullOrUndefined(value.value) && value.value.trim() !== "");
          }
          return (value.questionElementKey === v.questionElementKey && value.checkState);
        });
        acc.push(data);
      }
      return acc;
    }, []);
  }
  if (checkRequire(focusRequire, question.mandatory) && (logValue.indexOf(false) >= 0 || logValue.length === 0)) {
    return false;
  }
  return true;
};
const checkSelectedNumberOfChoiceSinglelineText = d => {
  return d.filter(e => e.choiceText.trim() !== "").length;
};
export const validateRequireSetValueMultipleChoiceSinglelineTextAuto = (question, value, focusRequire) => {
  if (checkRequire(focusRequire, question.mandatory) === false) {
    return true;
  } else if (checkRequire(focusRequire, question.mandatory) === true && Array.isArray(value) && value.length > 0 && question.requiredMinimumAnswer <= checkSelectedNumberOfChoiceSinglelineText(value)) {
    return true;
  }
    return false;
};
const isSelectedAppendTextBoxIsEmpty = (question, answer) => {
  const appendTextList = question
    .choices
    .reduce((acc, v, i, arr) => {
      acc[v.elementKey] = v.appendText;
      return acc;
    }, []);
  return answer.some(v => v.checkState === true && appendTextList[v.elementKey] === true && v.choiceText === "");
};
const checkSelectedNumberOfChoice = d => {
  return d
    .filter(e => e.checkState === true)
    .length;
};
const checkSelectedNumberOfChoiceAndInclude = d => {
  let data = d
    .value
    .filter(e => e.checkState === true)
    .length;
  if (d.isIncludeOther === true)
    data++;
  return data;
};
export const validateRequireSetValueMultipleChoiceMultipleAuto = (question, value, focusRequire) => {
  if (question.includeOther === false) {
    if (checkRequire(focusRequire, question.mandatory) === false) {
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === true && Array.isArray(value) && value.length > 0 && question.requireNumOfChoice <= checkSelectedNumberOfChoice(value) && value.some(v => v.checkState === true)) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  } else {
    if (checkRequire(focusRequire, question.mandatory) === false && !isNullOrUndefined(value) && Array.isArray(value.value) && value.isIncludeOther === true) {
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && !new RegExp(question.verifyOtherRegularExpression).test(value.IncludeOtherText)) || (!isNullOrUndefined(question.verifyOtherRegularExpression) && isNullOrUndefined(value.IncludeOtherText))) {
        return false;
      }

      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === false && !isNullOrUndefined(value) && Array.isArray(value.value) && value.isIncludeOther === false) {
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === true && !isNullOrUndefined(value) && Array.isArray(value.value) && value.isIncludeOther === true) {

      if (value.value.length > 0 && question.requireNumOfChoice > checkSelectedNumberOfChoiceAndInclude(value)) {
        return false;
      }
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && !new RegExp(question.verifyOtherRegularExpression).test(value.IncludeOtherText)) || (!isNullOrUndefined(question.verifyOtherRegularExpression) && isNullOrUndefined(value.IncludeOtherText))) {
        return false;
      }
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === true && !isNullOrUndefined(value) && Array.isArray(value.value) && value.value.length > 0 && question.requireNumOfChoice <= checkSelectedNumberOfChoiceAndInclude(value) && ((Object.values(value.value).some(v => v.checkState === true) && (value.isIncludeOther === true && !isNullOrUndefined(value.IncludeOtherText) && value.IncludeOtherText !== "")) || (Object.values(value.value).some(v => v.checkState === true) && value.isIncludeOther === false) || (Object.values(value.value).some(v => v.checkState === false) && value.isIncludeOther === true && !isNullOrUndefined(value.IncludeOtherText) && value.IncludeOtherText !== ""))) {
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && value.isIncludeOther === true && !new RegExp(question.verifyOtherRegularExpression).test(value.IncludeOtherText)) || (!isNullOrUndefined(question.verifyOtherRegularExpression) && isNullOrUndefined(value.IncludeOtherText) && value.isIncludeOther === true)) {
        return false;
      }
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        return false;
      }
      return true;
    } else {
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && isNullOrUndefined(value.IncludeOtherText) && value.isIncludeOther === true)) {
        return false;
      }
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && value.isIncludeOther === true && !new RegExp(question.verifyOtherRegularExpression).test(value.IncludeOtherText))) {
        return false;
      }

      return false;
    }
  }
};
export const validateRequireSetValueMultipleChoiceOneAuto = (question, value, focusRequire) => {
  if (question.includeOther === false) {
    if (checkRequire(focusRequire, question.mandatory) === false) {
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === true && Array.isArray(value) && value.length > 0 && Object.values(value).some(v => v.checkState === true)) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  } else {
    if (!isNullOrUndefined(value) && Array.isArray(value.value) && value.isIncludeOther === true) {
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && isNullOrUndefined(value.IncludeOtherText))) {
        return false;
      }
      if ((!isNullOrUndefined(question.verifyOtherRegularExpression) && !new RegExp(question.verifyOtherRegularExpression).test(value.IncludeOtherText))) {
        return false;
      }

      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === false && !isNullOrUndefined(value) && Array.isArray(value.value) && value.value.length > 0 && Object.values(value.value).some(v => v.checkState === true) && value.isIncludeOther === false) {
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) && !isNullOrUndefined(value) && Array.isArray(value.value) && value.value.length > 0 && (Object.values(value.value).some(v => v.checkState === true) || (value.isIncludeOther === true && !isNullOrUndefined(value.IncludeOtherText) && value.IncludeOtherText !== ""))) {
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        return false;
      }
      return true;
    } else if (checkRequire(focusRequire, question.mandatory) === false && !isNullOrUndefined(value) && Array.isArray(value.value) && value.value.length > 0 && (Object.values(value.value).some(v => v.checkState === true) || (value.isIncludeOther === true && !isNullOrUndefined(value.IncludeOtherText) && value.IncludeOtherText !== ""))) {
      return true;
    } else if (isNullOrUndefined(question.verifyOtherRegularExpression) && checkRequire(focusRequire, question.mandatory) === false) {
      return true;
    } else {
      return false;
    }
  }
};
export const validateAutoNext = (question, answers, focusRequire = false) => {
  if (MULTIPLECHOICEONE === question.type) {
    let answerValues = answers[question.elementKey];
    if (!question.includeOther) {
      answerValues = answers[question.elementKey] ? answerValues.value: '';
    }
    return validateRequireSetValueMultipleChoiceOneAuto(question, answerValues, focusRequire);
  } else if (MULTIPLECHOICEMULTIPLE === question.type) {
    let answerValues = answers[question.elementKey];
    if (!question.includeOther) {
      answerValues = answers[question.elementKey] ? answerValues.value: '';
    }
    return validateRequireSetValueMultipleChoiceMultipleAuto(question, answerValues, focusRequire);
  } else if (MULTIPLECHOICESINGLELINETEXT === question.type) {
    let answerValues = answers[question.elementKey];
    if (!question.includeOther) {
      answerValues = answers[question.elementKey] ? answerValues.value: '';
    }
    return validateRequireSetValueMultipleChoiceSinglelineTextAuto(question, answerValues, focusRequire);
  } else if ([MATRIXCHOICEMULTIPLE, MATRIXCHOICEONE, MATRIXSINGLELINETEXT, MATRIXDROPDOWN].indexOf(question.type) >= 0) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireSetValueArrayMatrixAuto(question, answerValues, question.type === MATRIXSINGLELINETEXT || question.type === MATRIXDROPDOWN, focusRequire);
  } else if (MATRIXRANKING === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireSetValueArrayMatrixRankingAuto(question, answerValues, focusRequire);
  } else if (RANKING === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
   return validateRankingRequireAuto(answerValues, question, focusRequire);
  } else if (NUMBER === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
   return validateNumberRequireAuto(answerValues , question, focusRequire);
  } else if (PHONE_SINGLELINETEXT === question.type){
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequirePhoneAuto(answerValues , question, focusRequire);
  } else if (EMAIL_SINGLELINETEXT === question.type){
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireEmailAuto(answerValues , question, focusRequire);
  } else if(RATING === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireRatingAuto(answerValues , question, focusRequire);
  } else if (RATINGSCALE === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireRatingScaleAuto(answerValues , question, focusRequire);
  } else if (NETPROMOTORSCORE === question.type) {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireNpsAuto(answerValues, question, focusRequire);
  } else {
    let answerValues = answers[question.elementKey] ? answers[question.elementKey].value: '';
    return validateRequireAuto(answerValues, question, focusRequire);
  }
}
export const addAutoNextToStore = (page, child) => {
  const extra = store.getState().extraReducer;
  if (isNullOrUndefined(extra.autoNext[page])) {
    extra.autoNext[page] = [];
  }
  if (extra.autoNext[page].indexOf(child) <0) {
     const questions = store.getState().conductReducer.questions;
      if (questions.some(x => x.elementKey === child && x.type  !== DISPLAYMESSAGE)) {
        extra.autoNext[page].push(child);
      }
  }
}
export const removeAutoNextToStore = (page, keepStateAutoNext) => {
  const conduct = store.getState().conductReducer;
  if (conduct.autoNext.use === true) {
    const extra = store.getState().extraReducer;
    const log = extra.autoNext[page];
    const index = log.indexOf(keepStateAutoNext);
    if (index >= 0) {
            log.splice( index,1);
            extra.autoNext[page] = log;
            keepStateAutoNext = "";
    }
  }

}
export const addAutoNextDone = (page) => {
  const extra = store.getState().extraReducer;
  extra.autoNextDone[page] = true;
}
export const autoNextCheckQuestionsDebounce = debounce( () =>  {
  const conduct = store.getState().conductReducer;
  if (conduct.autoNext.use === true && conduct.currentNode.type === PAGE) {
    const extra = store.getState().extraReducer;
    const answers = store.getState().answerReducer.data;
    const questions = store.getState().conductReducer.questions;
    const log = [];
      const pageElementKey = conduct.currentNode.elementKey;
      if (!isNullOrUndefined(pageElementKey)) {
          const questionsInPage = extra.autoNext[pageElementKey];
          questionsInPage.forEach(qElementKey => {
                  const question = questions.find(x => x.elementKey === qElementKey);
                  if (question && question.type !== DISPLAYMESSAGE) {
                        const valid = validateAutoNext(question, answers, conduct.autoNext.focusRequire);
                        log.push(valid);
                  } else if (question && question.type === DISPLAYMESSAGE) {
                    log.push(true);
                  }

          });

      }

      if (!log.some(x => x === false) && !isNullOrUndefined( document.querySelector('.button_next')) && ! extra.autoNextDone[pageElementKey]) {
          addAutoNextDone(pageElementKey);
          ActionSubscriptionService.clickAction();
      }
      return !log.some(x => x === false);
  }
  return false;
}, 10);

export const autoNextCheckQuestion =   () => {
  autoNextCheckQuestionsDebounce();
}
