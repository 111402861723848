import { isArray, isBoolean, isString, isInteger, gte, isEqual, gt } from "lodash";

class Expression {
    constructor() {
        if (this.constructor === Expression) {
            throw new TypeError(`Abstract class "Expression" cannot be instantiated directly.`);
        }

        Object.defineProperties(this, {
            type: {
                value: this.constructor.name,
                enumerable: true
            }
        });
    }

    reduce() {
        return this;
    }

    eval(context = Expression.survey_conduct_context()) {
        return this.reduce().eval(context);
    }

    run(context = Expression.survey_conduct_context()) {
        if (this.constructor === ConstantExpression) {
            return this.value;
        } else {
            return this.eval(context).run(context);
        }
    }

    static undefined() {
        return new UndefinedExpression();
    }

    static constant(value) {
        return new ConstantExpression(value);
    }

    static boolean(value) {
        return new BooleanExpression(value);
    }

    static true() {
        return TRUE;
    }

    static false() {
        return FALSE;
    }

    static text(value) {
        return new TextExpression(value);
    }

    static number(value) {
        return new NumberExpression(value);
    }

    static choice(choice_element_key) {
        return new ChoiceExpression(choice_element_key);
    }

    static index(index) {
        return new IndexExpression(index);
    }

    static rating(index) {
        return new RatingExpression(index);
    }

    static ranking(index) {
        return new RankingExpression(index);
    }

    static question_answer(question_element_key) {
        return new QuestionAnswerExpression(question_element_key);
    }

    static textual_question_answer(question_element_key) {
        return new TextualQuestionAnswerExpression(question_element_key);
    }

    static numeric_question_answer(quesiton_element_key) {
        return new NumericQuestionAnswerExpression(quesiton_element_key);
    }

    static rating_question_answer(question_element_key) {
        return new RatingQuestionAnswerExpression(question_element_key);
    }

    static sub_question_answer(sub_question_element_key) {
        return new SubQuestionAnswerExpression(sub_question_element_key);
    }

    static matrix_question_answer(sub_quesiton_element_key, choice_element_key) {
        return new MatrixQuestionAnswerExpression({ sub_quesiton_element_key, choice_element_key });
    }

    static matrix_textual_question_answer(sub_quesiton_element_key, choice_element_key) {
        return new MatrixTextualQuestionAnswerExpression(sub_quesiton_element_key, choice_element_key);
    }

    static multiple_choice_one_answer_question_answer(question_element_key) {
        return new MultipleChoiceOneAnswerQuestionAnswerExpression(question_element_key);
    }

    static multiple_choice_multiple_answer_question_answer(question_element_key) {
        return new MultipleChoiceMultipleAnswerQuestionAnswerExpression(question_element_key);
    }

    static matrix_multiple_choice_one_answer_question_answer(sub_question_element_key) {
        return new MatrixMultipleChoiceOneAnswerQuestionAnswerExpression(sub_question_element_key);
    }

    static matrix_multiple_choice_multiple_answer_question_answer(sub_question_element_key) {
        return new MatrixMultipleChoiceMultipleAnswerQuestionAnswerExpression(sub_question_element_key);
    }

    static ranking_question_answer(choice_element_key) {
        return new RankingQuestionAnswerExpression(choice_element_key);
    }

    static matrix_ranking_question_answer(sub_question_element_key) {
        return new MatrixRankingQuestionAnswer(sub_question_element_key);
    }

    static textual_context_variable_value(variable_name) {
        return new TextualContextVariableValueExpression(variable_name);
    }

    static unbind(name) {
        return new UnbindExpression(name);
    }

    static not(expression) {
        return new NotExpression(expression);
    }

    static any(expressions) {
        return new AnyExpression(expressions);
    }

    static all(expressions) {
        return new AllExpression(expressions);
    }

    static bind(name, value) {
        return new BindExpression(name, value);
    }

    static sequence(first, then) {
        return new SequenceExpression(first, then);
    }

    static and(left, right) {
        return new AndExpression(left, right);
    }

    static or(left, right) {
        return new OrExpression(left, right);
    }

    static contains(values, value) {
        return new ContainsExpression(values, value);
    }

    static not_contains(values, value) {
        return new NotContainsExpression(values, value);
    }

    static equal(left, right) {
        return new EqualExpression(left, right);
    }

    static not_equal(left, right) {
        return new NotEqualExpression(left, right);
    }

    static greater_than(left, right) {
        return new GreaterThanExpression(left, right);
    }

    static greater_than_or_equal(left, right) {
        return new GreaterThanOrEqualExpression(left, right);
    }

    static less_than(left, right) {
        return new LessThanExpression(left, right);
    }

    static less_than_or_equals(left, right) {
        return new LessThanOrEqualExpression(left, right);
    }

    static regex(pattern) {
        return new RegularExpressionExpression(pattern);
    }

    static matches(left, right) {
        return new MatchesExpression(left, right);
    }

    static array(js_array_expressions) {
        return new ArrayExpression(js_array_expressions);
    }

    static survey_conduct_context(questions = [], response = {}, variarbles = []) {
        return new SurveyConductContext(questions, response, variarbles);
    }

    static from_object(o) {
        if (o.type in NullaryExpressions) {
            return new NullaryExpressions[o.type](o.value);
        } else if (o.type in UnaryExpressions) {
            return new UnaryExpressions[o.type](Expression.from_object(o.expression));
        } else if (o.type in BinaryExpressions) {
            return new BinaryExpressions[o.type](
                Expression.from_object(o.left),
                Expression.from_object(o.right)
            );
        } else if (o.type === 'ArrayExpression') {
            return new ArrayExpression(o.expressions.map(ex => Expression.from_object(ex)));
        } else if (isArray(o)) {
            return new ArrayExpression(o.map(ex => Expression.from_object(ex)));
        } else {
            throw new Error(`Unsupported type "${o.type}"`);
            //return new ConstantExpression(o);
        }
    }

    static run_object_context(o, context) {
        return Expression.from_object(o).run(context);
    }

    static run_object_questions_response(o, questions, response) {
        return Expression.from_object(o).run(Expression.survey_conduct_context(questions, response));
    }
}

class SurveyConductContext {
    constructor(questions = [], response = {}, variarble_mapping = []) {
        Object.defineProperties(this, {
            questions: {
                value: questions,
                enumerable: true
            },
            response: {
                value: response,
                enumerable: true
            },
            variarble_mapping: {
                value: variarble_mapping,
                enumerable: true
            }
        });
    }
    get_quesiton(question_element_key) {
        let question = this.questions.find(q => q.elementKey === question_element_key);
        if (!question) {
            throw new Error(`Question with element key "${question_element_key}" not found.`);
        } else {
            return question;
        }
    }
    verify_question(question_element_key, expected_question_types) {
        let question = this.get_quesiton(question_element_key);
        if (!expected_question_types.includes(question.type)) {
            throw new Error(`Unsupported question type "${question.type}".`);
        } else {
            return true;
        }
    }
    get_parent_of_sub_question(sub_question_element_key) {
        let parent = this.questions.find(q => q.matrixQuestionItems && q.matrixQuestionItems.some(sq => sq.elementKey === sub_question_element_key));
        if (!parent) {
            throw new Error(`Parent of sub question with element key "${sub_question_element_key} not found."`);
        } else {
            return parent;
        }
    }
    get_parent_of_choice(choice_element_key) {
        let parent = this.questions.find(q => q.choices && q.choices.some(c => c.elementKey === choice_element_key));
        if (!parent) {
            throw new Error(`Parent of choice with element key "${choice_element_key}" not found.`);
        } else {
            return parent;
        }
    }
    get_variable_value(variarble_name) {
        let mapping = this.variarble_mapping.find(m => m.name === variarble_name);
        if (mapping) {
            return mapping.value;
        } else {
            return undefined;
        }
    }
}

class NullaryExpression extends Expression {
    constructor() {
        super();
        if (this.constructor === NullaryExpression) {
            throw new TypeError(`Abstract class "NullaryExpression" cannot be instantiated directly.`);
        }
    }
}

class UnaryExpression extends Expression {
    constructor(expression) {
        super();
        if (this.constructor === UnaryExpression) {
            throw new TypeError(`Abstract class "UnaryExpression" cannot be instantiated directly.`);
        }
        Object.defineProperties(this, {
            expression: {
                value: expression,
                enumerable: true
            }
        });
    }
}

class BinaryExpression extends Expression {
    constructor(left, right) {
        super();

        if (this.constructor === BinaryExpression) {
            throw new TypeError(`Abstract class "BinaryExpression" cannot be instantiated directly.`);
        }

        Object.defineProperties(this, {
            left: {
                value: left,
                enumerable: true
            },
            right: {
                value: right,
                enumerable: true
            }
        });
    }

    eval_with(operator, context = Expression.survey_conduct_context()) {
        let left = this.left.run(context);
        if (typeof left !== 'undefined') {
            let right = this.right.run(context);
            if (typeof right !== 'undefined') {
                return Expression.constant(operator(left, right));
            } else {
                return Expression.undefined();
            }
        } else {
            return Expression.undefined();
        }
    }
}

class ArrayExpression extends Expression {
    constructor(js_array) {
        super();
        Object.defineProperty(this, 'expressions', {
            value: js_array,
            enumerable: true
        });
    }

    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(this.expressions.map((ex) => ex.run(context)));
    }
}

class ConstantExpression extends NullaryExpression {
    constructor(value) {
        super();
        Object.defineProperty(this, 'value', {
            value: value,
            enumerable: true
        });
    }
    eval(context = Expression.survey_conduct_context()) {
        if (this.constructor === ConstantExpression) {
            return this;
        } else {
            return Expression.constant(this.value);
        }
    }
}

class BooleanExpression extends ConstantExpression {
    constructor(value) {
        super(value);
        if (!isBoolean(value)) {
            throw new Error(`Value "${value}" is not a boolean.`);
        }
    }
}

class TrueExpression extends BooleanExpression {
    constructor() {
        super(true);
    }
}

class FalseExpression extends BooleanExpression {
    constructor() {
        super(false);
    }
}

const TRUE = new TrueExpression();
const FALSE = new FalseExpression();

class UndefinedExpression extends NullaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(undefined);
    }
}

class TextExpression extends ConstantExpression {
    constructor(value) {
        super(value);
        if (!isString(this.value)) {
            throw new Error(`Value "${value}" is not text.`);

        }
    }
}

class NumberExpression extends ConstantExpression {
    constructor(value) {
        super(value);
        // Edit number validate expression 25/102018
        if (!new RegExp('^-?\\d+\\.?\\d*$').test(this.value)) {
            throw new Error(`Value "${value}" is not a number.`);
        }
    }
}

class IndexExpression extends NumberExpression {
    constructor(value) {
        super(value);
        if (!(isInteger(this.value) && gte(this.value, 0))) {
            throw new Error(`Value "${value}" is an invalide index value`);
        }
    }
}

class ChoiceExpression extends TextExpression {
}

class RatingExpression extends IndexExpression {
}

class RankingExpression extends IndexExpression {
}

class QuestionAnswerExpression extends TextExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(context.response && context.response[this.value] && context.response[this.value].value);
    }
}

class TextualQuestionAnswerExpression extends QuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let textual_question_types = ['singleline_text_question',
            'multipleline_text_question',
            'email_question'];

        if (context.verify_question(this.value, textual_question_types)) {
            let answer = super.eval(context).run(context);
            if (typeof answer !== 'undefined') {
                return Expression.text(answer);
            } else {
                return Expression.undefined();
            }
        }
    }
}

class NumericQuestionAnswerExpression extends QuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        if (context.verify_question(this.value, ['numeric_question'])) {
            let answer = super.eval(context).run(context);
            if (typeof answer !== 'undefined') {
                return Expression.number(answer);
            } else {
                return Expression.undefined();
            }
        }
    }
}

class RatingQuestionAnswerExpression extends QuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let supported_question_types = ['rating_scale_question', 'rating_question', 'nps_question'];
        if (context.verify_question(this.value, supported_question_types)) {
            let answer = super.eval(context).run(context);
            // edit rating question 19/10/2018
            if (typeof answer !== 'undefined' && answer !== '') {
                return Expression.rating(answer);
            } else {
                return Expression.undefined();
            }
        }
    }
}

class SubQuestionAnswerExpression extends TextExpression {
    eval(context = Expression.survey_conduct_context()) {
        let parent = context.get_parent_of_sub_question(this.value);
        let answer = Expression.question_answer(parent.elementKey).run(context);
        if (isArray(answer)) {
            return Expression.array(answer.filter(v => v.questionElementKey === this.value)
                .map(Expression.constant));
        } else {
            return Expression.undefined();
        }
    }
}

class MatrixMultipleChoiceOneAnswerQuestionAnswerExpression extends SubQuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let parent = context.get_parent_of_sub_question(this.value);
        if (context.verify_question(parent.elementKey, ['matrix_choice_one_answer_question'])) {
            let sub_question_answers = super.eval(context).run(context);
            if (isArray(sub_question_answers)) {
                let selected_choice = sub_question_answers.find(c => c.checkState === true);
                if (typeof selected_choice !== 'undefined') {
                    return Expression.choice(selected_choice.choiceElementKey);
                } else {
                    return Expression.undefined();
                }
            } else {
                return Expression.undefined();
            }
        }
    }
}

class MatrixMultipleChoiceMultipleAnswerQuestionAnswerExpression extends SubQuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let parent = context.get_parent_of_sub_question(this.value);
        if (context.verify_question(parent.elementKey, ['matrix_choice_multiple_answer_question'])) {
            let sub_question_answers = super.eval(context).run(context);
            if (typeof sub_question_answers !== 'undefined') {
                return Expression.array(
                    sub_question_answers
                        .filter(c => c.checkState === true)
                        .map(c => Expression.choice(c.choiceElementkey))
                );
            } else {
                return Expression.undefined();
            }
        }
    }
}




class MatrixQuestionAnswerExpression extends ConstantExpression {
    eval(context = Expression.survey_conduct_context()) {
        let parent = context.get_parent_of_sub_question(this.value.sub_quesiton_element_key);
        if (parent !== context.get_parent_of_choice(this.value.choice_element_key)) {
            throw new Error(`Sub question with element key "${this.value.sub_quesiton_element_key}" and choice with element key "${this.value.choice_element_key}" have different parent.`);
        } else {
            let question_answer = Expression.sub_question_answer(this.value.sub_question_element_key).run(context);
            if (isArray(question_answer)) {
                let answer = question_answer.find(a => a.choiceElementKey === this.value.choice_element_key);
                if (typeof answer !== 'undefined') {
                    return Expression.constant(answer);
                } else {
                    return Expression.undefined();
                }
            } else {
                return Expression.undefined();
            }
        }
    }
}

class MatrixTextualQuestionAnswerExpression extends MatrixQuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        if (context.verify_question(context.get_parent_of_sub_question(this.value.sub_quesiton_element_key),
            ['matrix_singleline_text_question'])) {
            let answer = super.eval(context).run(context);
            if (typeof answer !== 'undefined') {
                return Expression.text(answer);
            } else {
                return Expression.undefined();
            }
        }
    }
}


class RankingAnswerExpression extends NullaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        let answer = context.get_ranking_answer(this.value.choice_element_key);
        if (typeof answer !== 'undefined') {
            return Expression.ranking(answer);
        } else {
            return Expression.undefined();
        }
    }
}

class RegularExpressionExpression extends TextExpression {
}

class MultipleChoiceOneAnswerQuestionAnswerExpression extends QuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let supported_question_types = ['multiple_choice_one_answer_question',
            'dropdown_question'];
        if (context.verify_question(this.value, supported_question_types)) {
            let answer = super.eval(context).run(context);
            let question = context.get_quesiton(this.value);
            if (question.type === 'multiple_choice_one_answer_question' && isArray(answer)) {
                let selected_choice = answer.find(ch => ch.checkState === true);
                if (selected_choice) {
                    return Expression.choice(selected_choice.elementKey);
                } else {
                    return Expression.undefined();
                }
            } else if (question.type === 'dropdown_question' && typeof answer !== 'undefined') {
                return Expression.choice(answer);
            }
            else {
                return Expression.undefined();
            }
        }
    }
}

class MultipleChoiceMultipleAnswerQuestionAnswerExpression extends QuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        if (context.verify_question(this.value, ['multiple_choice_multiple_answer_question'])) {
            let answer = super.eval(context).run(context);
            if (typeof answer === 'undefined') {
                return Expression.undefined();
            } else if (isArray(answer)) {
                return Expression.array(answer.filter(c => c.checkState === true).map(c => Expression.choice(c.elementKey)));
            } else {
                throw new Error('Answer of multiple choice multiple answer is malformed');
            }
        }
    }
}

class RankingQuestionAnswerExpression extends TextExpression {
    eval(context = Expression.survey_conduct_context()) {
        let question = context.get_parent_of_choice(this.value);
        if (context.verify_question(question.elementKey, ['ranking_question'])) {
            let question_answer = Expression.question_answer(question.elementKey).run(context);
            if (isArray(question_answer)) {
                let index = question_answer.indexOf(this.value);
                if (index !== -1) {
                    return Expression.ranking(index);
                } else {
                    return Expression.undefined();
                }
            } else {
                return Expression.undefined();
            }
        }
    }
}

class MatrixRankingQuestionAnswer extends SubQuestionAnswerExpression {
    eval(context = Expression.survey_conduct_context()) {
        let question = context.get_parent_of_sub_question(this.value);
        if (context.verify_question(question.elementKey, ['matrix_ranking_question'])) {
            let question_answer = super.eval(context).run(context);
            if (isArray(question_answer)) {
                let selected_ranking = question_answer.find(c => c.checkState === true);
                if (typeof selected_ranking !== 'undefined') {
                    let index = question.matrixChoices.findIndex(c => c.elementKey === selected_ranking.choiceElementKey);
                    if (index !== -1) {
                        return Expression.ranking(index);
                    } else {
                        return Expression.undefined();
                    }
                } else {
                    return Expression.undefined();
                }
            } else {
                return Expression.undefined();
            }
        }
    }
}

class TextualContextVariableValueExpression extends TextExpression {
    eval(context = Expression.survey_conduct_context()) {
        let value = context.get_variable_value(this.value);
        if (typeof value !== 'undefined') {
            return Expression.text(value);
        } else {
            return Expression.undefined();
        }
    }
}


const NullaryExpressions = {
    UndefinedExpression,
    ConstantExpression,
    BooleanExpression,
    TrueExpression,
    FalseExpression,
    TextExpression,
    NumberExpression,
    IndexExpression,
    ChoiceExpression,
    RatingExpression,
    RankingExpression,
    RegularExpressionExpression,
    QuestionAnswerExpression,
    SubQuestionAnswerExpression,
    TextualQuestionAnswerExpression,
    MatrixTextualQuestionAnswerExpression,
    NumericQuestionAnswerExpression,
    MultipleChoiceOneAnswerQuestionAnswerExpression,
    MatrixMultipleChoiceOneAnswerQuestionAnswerExpression,
    MultipleChoiceMultipleAnswerQuestionAnswerExpression,
    MatrixMultipleChoiceMultipleAnswerQuestionAnswerExpression,
    RatingQuestionAnswerExpression,
    RankingAnswerExpression,
    MatrixRankingQuestionAnswer,
    TextualContextVariableValueExpression
};

class UnbindExpression extends UnaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(context[this.expression.run(context).toString()]);
    }
}

class NotExpression extends UnaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(!(this.expression.run(context)));
    }
}

class AnyExpression extends UnaryExpression {
    reduce() {
        let [ex, ...exes] = this.expression.expressions;
        switch (true) {
            case (ex === undefined):
                return Expression.false();
            case (exes.length === 0):
                return ex;
            default:
                return Expression.or(
                    ex,
                    Expression.any(Expression.array(exes))
                );
        }
    }
}

class AllExpression extends UnaryExpression {
    reduce() {
        let [ex, ...exes] = this.expression.expressions;
        switch (true) {
            case (ex === undefined):
                return Expression.false();
            case (exes.length === 0):
                return ex;
            default:
                return Expression.and(
                    ex,
                    Expression.all(Expression.array(exes))
                );
        }
    }
}
const UnaryExpressions = {
    UnbindExpression,
    NotExpression,
    AnyExpression,
    AllExpression
};

class BindExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(context[this.left.run(context).toString()] = this.right.run(context));
    }
}

class SequenceExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return (this.left.run(context), this.right.eval(context));
    }
}

class AndExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        let left = this.left.run(context);
        if (left) {
            let right = this.right.run(context);
            if (right) {
                return Expression.true();
            } else {
                return Expression.false();
            }
        } else {
            return Expression.false();
        }
    }
}

class OrExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        let left = this.left.run(context);
        if (!left) {
            let right = this.right.run(context);
            if (!right) {
                return Expression.false();
            } else {
                return Expression.true();
            }
        } else {
            return Expression.true();
        }
    }
}

class ContainsExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return this.eval_with(((a, v) => a.includes(v)), context);
    }
}

class NotContainsExpression extends BinaryExpression {
    reduce() {
        return Expression.not(Expression.contains(this.left, this.right));
    }
}

class EqualExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return this.eval_with(isEqual, context);
    }
}

class NotEqualExpression extends BinaryExpression {
    reduce() {
        return Expression.not(Expression.equal(this.left, this.right));
    }
}

class GreaterThanExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return this.eval_with(gt, context);
    }
}

class GreaterThanOrEqualExpression extends BinaryExpression {
    reduce() {
        return Expression.or(
            Expression.greater_than(this.left, this.right),
            Expression.equal(this.left, this.right)
        );
    }
}

class LessThanExpression extends BinaryExpression {
    reduce() {
        return Expression.not(
            Expression.greater_than_or_equal(this.left, this.right)
        );
    }
}

class LessThanOrEqualExpression extends BinaryExpression {
    reduce() {
        return Expression.not(
            Expression.greater_than(this.left, this.right)
        );
    }
}

class MatchesExpression extends BinaryExpression {
    eval(context = Expression.survey_conduct_context()) {
        return Expression.constant(
            (new RegExp(this.right.run(context))).test(this.left.run(context))
        );
    }
}

const BinaryExpressions = {
    BindExpression,
    SequenceExpression,
    AndExpression,
    OrExpression,
    ContainsExpression,
    NotContainsExpression,
    EqualExpression,
    NotEqualExpression,
    GreaterThanExpression,
    GreaterThanOrEqualExpression,
    LessThanExpression,
    LessThanOrEqualExpression
}

export default Expression;
