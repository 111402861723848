import React from "react";
import parse from 'html-react-parser';
import "./ThankyouComponent.scss";
import { isNullOrUndefined } from "../Utility/index";
import { convertTextToScreen, evaluateExpression } from "../Utility/ConditionValidator";

class ThankyouComponent extends React.Component {
  constructor(props) {
    super(props);
    this.parseContextVariable = this.parseContextVariable.bind(this);
  }
  parseContextVariable(content) {
    const { conduct } = this.props;

    var replacements = {};
    conduct.contextVariables.forEach(function(ctx) {
      replacements[`{${ctx.name}}`] = decodeURIComponent(ctx.value);
    });

    var parseContent = content.replace(/{\w+}/g, function(a) {
      return replacements[a] || a;
    });
    parseContent = parseContent.replace(/\{\w+\}/g, '');

    return parseContent;
  }
  render() {
    if (isNullOrUndefined(this.props.conduct)) {
      return "";
    }
    const { surveyContent } = this.props.conduct;
    return (
      <div className="page_body">
        <div className="thank_you_description">
          {parse(this.parseContextVariable(evaluateExpression(convertTextToScreen(surveyContent.thankyouContent))))}
        </div>
      </div>
    );
  }
}

export default ThankyouComponent;
