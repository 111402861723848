import React from "react";
import parse from 'html-react-parser';
import { isNullOrUndefined } from "../Utility/index";

class DuplicateComponent extends React.Component {
  render() {
    const { allowOnlyOneResponsePerUniqueUrlContent } = this.props.conduct.surveyContent;

    return (
      <div className="page_body">
        {isNullOrUndefined(allowOnlyOneResponsePerUniqueUrlContent) ? (
          ""
        ) : (
          <div className="duplicate_description">
            {parse(allowOnlyOneResponsePerUniqueUrlContent)}
          </div>
        )}
      </div>
    )
  }
}
export default DuplicateComponent;
