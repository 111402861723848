import {
  SET_API_URL,
  STACK_ACTION_LOG,
  ANSWER_NOT_SAVE,
  APP_SET_PROGRESS
} from "../ActionTypes/AppType";
const appReducer = (
  state = {
    stackActionLog: [],
    notsave: [],
    cancelAnswers: [],
    complete: {percent: 0 , all: 0, doPass: 0}
  },
  action
) => {
  switch (action.type) {
    case STACK_ACTION_LOG:
      if (action.isPop) {
        let copy = [];
        if (state.stackActionLog.length > 0) {
          copy = [...state.stackActionLog];
          copy.pop();
        }
        state = {
          ...state,
          stackActionLog: [...copy]
        }
      } else {
        state = {
          ...state,
          stackActionLog: [...state.stackActionLog, action.data]
        }
      }

      break;
    case SET_API_URL:
      state = {
        ...state,
        pathForApiCall: action.url
      }
      break;
    case ANSWER_NOT_SAVE:
        state = {
          ...state,
          notsave: action.notsave
        }
      break;
    case APP_SET_PROGRESS:
      state = {
        ...state,
        complete: {...action.progress}
      }
    break;
    default:
      state = {
        ...state
      };
  }

  return state;
};

export default appReducer;
