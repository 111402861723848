import React from "react";
import QuestionDetailsComponent from "./QuestionDetailsComponent";
import { isNullOrUndefined } from "../Utility/index";


class NumericComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }
  handleBlur(event) {
    if (!isNaN(Number.parseFloat(event.target.value))) {
      this.props.setAnswer(this.props, event);
    } else {
      event.target.value = "";
      this.props.setAnswer(this.props, event);
    }
  }
  changeValue(event) {
    event.target.focus();
  }
  eventInput (event) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  }

  render() {
    const { question, answer } = this.props;
    const { value } = answer;
    const { elementKey, questionContent } = question;
    const { helpText } = questionContent;
    return (
      <div id={`${elementKey}`} className={`question numeric`}>
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <input
            id={`numberic${this.props.question.elementKey}`}
            type="number"
            max={question.maximumValue}
            min={question.minimumValue}
            pattern="[0-9]*"
            onInput={e=> this.eventInput(e)}
            defaultValue={isNullOrUndefined(value)?"":value+""}
            placeholder={helpText}
            onChange={e=>this.changeValue(e)}
            onBlur={e=>this.handleBlur(e)}
          />
        </div>
      </div>
    );
  }
}

export default NumericComponent;
