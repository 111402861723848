import React from "react";
import RatingComponent from "./RatingComponent";

class RatingScaleComponent extends React.Component {
  render() {
    const { question } = this.props;
    let ratingProps = { ...this.props };
    ratingProps.question.beginLabel = question.ratingScaleContent.scaleFromLabelText;
    ratingProps.question.endLabel = question.ratingScaleContent.scaleToLabelText;
    ratingProps.question.choices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      .slice(question.ratingScaleFromNum, question.ratingScaleToNum+1)
      .map((ch,i) => ({
        index: i,
        choiceText: ch.toString()
      }));

    return <RatingComponent { ...ratingProps } />;
  }
}


export default RatingScaleComponent;
