import React from "react";
import "./ExpireComponent.scss";

class ExpireComponent extends React.Component {
  render() {
    return (
      <div className="expire-page">
        <div className="expire-container">
          <div className="expire-content">
            <div className="expire">
              <div className="triangle">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="30%" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
                  <circle fill="#ececec" cx="17" cy="17" r="14"></circle>
                  <circle fill="#ffffff" cx="17" cy="17" r="11"></circle>
                  <g>
                    <rect fill="#dfdfdf" x="16" y="8" width="2" height="9"></rect>
                    <rect fill="#dfdfdf" x="18.2" y="16" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 46.8342 19.3994)" width="2.4" height="6.8"></rect>
                    <circle fill="#dfdfdf" cx="17" cy="17" r="2"></circle>
                  </g>
                  <circle fill="#ebebeb" cx="17" cy="17" r="1"></circle>
                  <path fill="#d8d8d8" d="M11.9,42l14.4-24.1c0.8-1.3,2.7-1.3,3.4,0L44.1,42c0.8,1.3-0.2,3-1.7,3H13.6C12.1,45,11.1,43.3,11.9,42z"></path>
                  <g>
                    <path fill="#bebebe" d="M26.4,39.9c0-0.2,0-0.4,0.1-0.6s0.2-0.3,0.3-0.5s0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1s0.5,0,0.7,0.1   s0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.6s0,0.4-0.1,0.6s-0.2,0.3-0.3,0.5s-0.3,0.2-0.5,0.3s-0.4,0.1-0.7,0.1   s-0.5,0-0.6-0.1s-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.5S26.4,40.1,26.4,39.9z M29.2,36.8h-2.3L26.5,27h3L29.2,36.8z"></path>
                  </g>
                </svg>
              </div>
              <div className="text">
                <div className="t2">
                  We are sorry, this survey has already expired.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpireComponent;
