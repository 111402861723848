const extraReducer = (
  state = {
    autoNext: {},
    autoNextDone: {}
  },
  action
) => {
  switch (action.type) {

    default:
      state = {
        ...state
      };
  }

  return state;
};

export default extraReducer;
