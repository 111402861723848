import React from "react";
import { connect } from "react-redux";
import WelcomeComponent from "../Components/WelcomeComponent";
import {
  selectConductQuestion,
  setConductNode
} from "../Actions/ConductAction";
import ButtonContainer from "./ButtonContainer";
import LanguageComponent from "../Components/LanguageComponent";

const WelcomeContainer = props => {
  const { conduct } = props;

  return (
    <div className={"page"} id="welcome_page">
      <LanguageComponent
        conduct={conduct}
      />
      <WelcomeComponent conduct={conduct} />
      <ButtonContainer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    conduct: state.conductReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setQuestions: id => {
      dispatch(selectConductQuestion(id));
    },
    setConductNode: page => {
      dispatch(setConductNode(page));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeContainer);
