import $ from "jquery-slim";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from 'uuid';

export const unAuthorized = message => {
    // console.log(message);
};

export const encryptWithCryptoJS = plainText => {
    const key = CryptoJS.enc.Utf8.parse("qrsurveyfeedback");
    const iv1 = CryptoJS.enc.Utf8.parse("qrsurveyfeedback");
    var encrypted = CryptoJS.AES.encrypt(plainText, key, {
        keySize: 16,
        iv: iv1,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    encrypted = encrypted.toString().replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a4S').replace(/=/g,'e1Q2u3A');
    encrypted = encrypted.split("").reverse().join("");

    return encrypted;
}

export const decryptionWithCryptoJS = cipher => {
    const key = CryptoJS.enc.Utf8.parse("qrsurveyfeedback");
    const iv1 = CryptoJS.enc.Utf8.parse("qrsurveyfeedback");

    var cipherText = cipher.toString().split("").reverse().join("").replace(/p1L2u3S/g, '+' ).replace(/s1L2a4S/g, '/').replace(/e1Q2u3A/g, '=');
    const plainText = CryptoJS.AES.decrypt(cipherText, key, {
        keySize: 16,
        iv: iv1,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return plainText.toString(CryptoJS.enc.Utf8);
}

export const getQRInformation = path => {
    return path.split('&').map((item) => {
        const param = item.split('=');
        return { [param[0]]: param[1] }
      }).reduce((acc, cur) => {
        return {...acc, ...cur}
      }, {});
}

export const checkValidQRInfo = qrInfo => {
    return qrInfo.hasOwnProperty('channel') && qrInfo.hasOwnProperty('branchcode') && qrInfo.hasOwnProperty('empcode')
        && qrInfo['channel'].length > 0;
}

export const getToken = () => {
    const now = new Date()
    const ttl = new Date(now.setMonth(now.getMonth() + 2));
    const key = 'kbankqrsurvey';
    const tokenValueFromStorage = localStorage.getItem(key)

    let result = '';
    
    if (!tokenValueFromStorage) {
        // Key doesn't exist, generate new token with expiry                
        const token = generateToken();

        const tokenValue = {
            token: token,
            expiry: ttl,
        }
        localStorage.setItem(key, JSON.stringify(tokenValue))

        result = token;
    } else {
        const tokenValue = JSON.parse(tokenValueFromStorage);

        if (now.getTime() > tokenValue.expiry) {
            const token = generateToken();

            const tokenValue = {
                token: token,
                expiry: ttl,
            }
            localStorage.setItem(key, JSON.stringify(tokenValue))

            result = token;
        } else {
            result = tokenValue.token;
        }
    }

    return result;
}

const generateToken = () => {
    let salt = uuidv4();
    return encryptWithCryptoJS(encryptWithCryptoJS(salt));
}

export const parseDateFormat = format => {
    let symbol = new RegExp("[^a-zA-Z0-9]", "i").exec(format);
    if (symbol === null) {
        symbol = ["/"];
    }
    format = format.split(symbol[0]);
    let date = [];
    format.filter(d => {
        if (d.toLowerCase() === "d" || d.toLowerCase() === "dd") {
            date.push("d");
        } else if (d.toLowerCase() === "m" || d.toLowerCase() === "mm") {
            date.push("m");
        } else if (d.toLowerCase() === "y" || d.toLowerCase() === "yyyy") {
            date.push("Y");
        }
        return true;
    });

    return date.join("/");
};
export const renderDate = (now, format = "d/m/Y", offset = 0) => {
    let symbol = new RegExp("[^a-zA-Z0-9]", "i").exec(format);
    if (symbol === null) {
        symbol = ["/"];
    }
    format = format.split(symbol[0]);
    let date = [];
    format.filter(d => {
        if (d.toLowerCase() === "d" || d.toLowerCase() === "dd") {
            date.push(now.getDate() < 10 ? "0" + now.getDate() : now.getDate());
        } else if (d.toLowerCase() === "m" || d.toLowerCase() === "mm") {
            date.push(
                now.getMonth() * 1 > 8 ?
                now.getMonth() * 1 + 1 :
                "0" + (now.getMonth() * 1 + 1)
            );
        } else if (d.toLowerCase() === "y" || d.toLowerCase() === "yyyy") {
            date.push(now.getFullYear() + offset);
        }
        return true;
    });

    return date.join("/");
};

export const findAllNode = surveyFlow => {
    let obj = [];

    const notType = ["SURVEY_FLOW"];
    const nodeFilter = node => {
        if (node.hasOwnProperty("children") && !isNull(node.children)) {
            node.children.filter(v => {
                nodeFilter(v);
                return true;
            });
        }
        if (notType.indexOf(node.type) < 0) obj.push(node);
    };
    let manageNodeInLine = nl => {
        let pageNl = -1;
        let nlArr = [];
        nl.filter((vm, im) => {
            if (vm.type === "Page") {
                pageNl++;
                nlArr[pageNl] = [];
            } else {
                nlArr[pageNl].push(vm);
            }
            return true;
        });
        return nlArr.reverse();
    };
    nodeFilter(surveyFlow);
    //console.log(obj);
    return manageNodeInLine(obj.reverse());
};
const addStyleToWindow = (elementStyle, json) => {
    if (!isArray(window[elementStyle])) {
        window[elementStyle] = [];
    }
    Object.keys(json).filter(m => {
        window[elementStyle][m] = json[m];

        return true;
    });
};
export const addStyleHeaderObject = (
    jsonStyle = {},
    elementStyle = "mStyle",
    flagElement = 0
) => {
    const pureElement = elementStyle;
    let tag = "";
    if (flagElement === 0) {
        tag = "class=" + elementStyle;
        elementStyle = "." + elementStyle;
    } else if (flagElement === 1) {
        tag = "id=" + elementStyle;
        elementStyle = "#" + elementStyle;
    }
    addStyleToWindow(elementStyle, jsonStyle);
    jsonStyle = window[elementStyle];
    let css = "";
    Object.keys(jsonStyle).filter(m => {
        if (isNullOrUndefined(jsonStyle[m])) return true;
        css += m + "{";
        Object.keys(jsonStyle[m]).filter(k => {
            if (!isNullOrUndefined(jsonStyle[m][k]))
                css += k + ":" + jsonStyle[m][k] + ";";
            return true;
        });
        css += "}";
        return true;
    });
    //console.log(css);

    if ($(elementStyle).length > 0) {
        $(elementStyle).replaceWith(
            "<style  type='text/css'  " + tag + ">" + css + "</style>"
        );
    } else {
        let findElement = "";
        $.each($("style"), function(i, v) {
            if (!isNullOrUndefined($(v).attr("class")) &&
                $(v).attr("class") !== pureElement
            ) {
                findElement = "." + $(v).attr("class");
            }
        });

        if (findElement === "") {
            $("head").append(
                "<style  type='text/css'  " + tag + ">" + css + "</style>"
            );
        } else {
            $(elementStyle).remove();
            $(
                "<style  type='text/css'  " + tag + ">" + css + "</style>"
            ).insertBefore(findElement);
        }
    }
};
export const appendStyleHeaderText = (elementStyle, css, flagElement = 0) => {
    if (isNullOrUndefined(css)) return;
    let tag = "";
    //const pureElement = elementStyle;
    if (flagElement === 0) {
        tag = "class=" + elementStyle;
        elementStyle = "." + elementStyle;
    } else if (flagElement === 1) {
        tag = "id=" + elementStyle;
        elementStyle = "#" + elementStyle;
    }
    if ($(elementStyle).length === 0) {
        $("head").append(
            "<style  type='text/css'  " + tag + ">" + css + "</style>"
        );
    }
};
export const cloneChoices = props => {
    const { choices, includeOther, questionContent } = props.question;
    let choicesCopy = [
        ...choices.filter(g => {
            return g.visible === true;
        })
    ];
    if (includeOther) {
        choicesCopy.push({
            elementKey: `${props.question.elementKey}`,
            choiceText: isNullOrUndefined(questionContent.includeOtherText) ?
                "" :
                questionContent.includeOtherText,
            appendText: true
        });
    }
    return choicesCopy;
};
export const setTimeMidnight = (t, offset = 0) => {
    t.setHours(0);
    t.setMinutes(0);
    t.setMilliseconds(0);
    if (offset > 0) {
        t.setFullYear(t.getFullYear() + offset);
    }
    return t;
};
export const parseUrl = (contextVariables, redirectThankyouPageUrl, props) => {
    const { conduct } = props;

    let replacements = {};
    contextVariables.forEach(function(ctx) {
        replacements[`{${ctx.name}}`] = ctx.value;
    });

    let thankyouUrl = redirectThankyouPageUrl.replace(/{\w+}/g, function(a) {
        return replacements[a] || a;
    });
    thankyouUrl = thankyouUrl.replace(/\{\w+\}/g, "");

    //Add result id
    if (conduct.isPublish) {
        thankyouUrl += `&resultid=${conduct.conductID}`;
    } else {
        thankyouUrl += `&resultid=0`;
    }
    //Add language
    if (conduct.currentLanguageID === 2) {
        thankyouUrl += "&lang=en";
    }

    return thankyouUrl;
}
export function isNull(arg) {
    return arg === null;
}
export function isNullOrUndefined(arg) {
    return arg === null || arg === undefined;
}
export function isString(arg) {
    return typeof arg === 'string';
}
export function isNumber(arg) {
    return typeof arg === 'number';
}

export function isArray(ar) {
    return ar instanceof Array
        || Array.isArray(ar)
        || (ar && ar !== Object.prototype && isArray(ar.__proto__));
}
export function convertDateToText(date) {
    function changeFormat(data) {
        if (data.length < 2) {
            data = '0' + data;
        }
        return data;
    }
    let month = date.getMonth();
    const d = date.getDate() + '';
    if (month + 1 > 12) {
        month = '1';
    } else {
        month = (month + 1) + '';
    }
    return date.getFullYear() + '-' + changeFormat(month) + '-' + changeFormat(d);
}
export function diffDate(newDate, compareDate) {
    if (isNullOrUndefined(newDate) || isNullOrUndefined(compareDate)) {
        throw new Error('Data is not date');
    }
    const newNum = new Date(convertDateToText(newDate)).getTime();
    const compareNum = new Date(convertDateToText(compareDate)).getTime();
    const diff = compareNum - newNum;
    return diff;
}
export function checkIsExpire(action) {
    let isExpire = false;
    if (!(window.location.pathname.indexOf('/p/') === 0)) {
        if (!isNullOrUndefined(action.payload.expire) && action.payload.expire === true) {
            isExpire = true;
        } else {
            if (!isNullOrUndefined(action.payload.expireDateTime)) {
                if (diffDate(new Date(action.payload.expireDateTime), new Date()) >= 0) {
                    isExpire = true;
                } else {
                    isExpire = false;
                }
            }
        }
    }
    return isExpire;
}
