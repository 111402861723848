import React, { Component } from "react";
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import parse from 'html-react-parser';
import QuestionDetailsComponent from "./QuestionDetailsComponent";
const SortableItem = SortableElement(({value}) => <div className="sort-item"><DragHandle/> {parse(value)}</div>);
const DragHandle = sortableHandle(() => <span className="drag-handle"></span>);
const SortableList = SortableContainer(({items, question}) => {
  return (
    <div className="sort-list">
      {items.map((value, index) => (
        <SortableItem  key={`rankinginex${question.elementKey}${index}`} index={index} value={value.choiceText} />
      ))}
    </div>
  );
});
const swapArrayElements = (arr, indexA, indexB) =>{
  const temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};
class RankingComponent extends Component {
  state = {
    items: [ ],
  };
  componentDidMount() {
    const {  answer } = this.props;
    const { choices } = this.props.question;
    if (Array.isArray(answer.value)) {
      const dataTemp = [];
      answer.value.forEach(a => {
        const data = choices.find(e => e.elementKey === a);
         if (data) {
           dataTemp.push(data);
         }
      });
      this.setState({items: dataTemp});
    } else {

      this.setState({items: Object.assign([],choices)});
      const data = choices.map(g => g.elementKey);
      this.props.setAnswer(this.props, data);
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: swapArrayElements(items, oldIndex, newIndex)
    }), ()=> {
      this.props.setAnswer(this.props, this.state.items.map(f=>f.elementKey));
    });
  };
  render() {
    const { question } = this.props;
    return (
      <div
      id={`${this.props.question.elementKey}`}
      className={`question ranking`}
    >
      <QuestionDetailsComponent {...this.props} />
            <div className="question_element ranking_element">

              <SortableList  items={this.state.items} question={question} onSortEnd={this.onSortEnd} />
            </div>
      </div>
    );
  }
}

export default RankingComponent;
