import React, { Component } from "react";
import { connect } from "react-redux";
import NodeContainer from "./NodeContainer";
import ButtonContainer from "./ButtonContainer";
import { NOTFOUND, ERROR_500, LOADING } from "../Utility/TypeValues";
import Exp from "../Expression/expression";
import { isNull } from "../Utility/index";
import LanguageComponent from "../Components/LanguageComponent";
import uniqID from "uniqid";
import {
  validateConditionToRoot
} from "../Utility/ConditionValidator";

class GroupsWrapperContainer extends Component {
  haveShowBtn() {
    const { conduct } = this.props;
    return (
      <div className="page" id={`${this.props.nodes.elementKey?this.props.nodes.elementKey:""}`}>
        {isNull(this.props.nodes.children) ? (
          ""
        ) : (
          <div className="page_body">
            <NodeContainer
              key={`nodegroupsWrapper${this.props.nodes.elementKey}`}
              nodes={this.props.nodes.children}
            />
          </div>
        )}

        {[LOADING, ERROR_500, NOTFOUND].indexOf(conduct.node) < 0 ? (
          <ButtonContainer
            key={`btngroupsWrapper${this.props.nodes.elementKey}`}
            nodes={this.props.nodes.children}
            buttonNext={conduct.surveyContent.buttonNext}
            buttonPrevious={conduct.surveyContent.buttonPrevious}
          />
        ) : (
          "์"
        )}
      </div>
    );
  }
  notHaveBtn() {
    return (
      <div className="page" id={`${this.props.nodes.elementKey?this.props.nodes.elementKey:""}`}>
        <LanguageComponent
          conduct={this.props.conduct}
        />
        {isNull(this.props.nodes.children) ? (
          ""
        ) : (
          <div className="page_body">
            <NodeContainer
              key={`${this.props.nodes.elementKey+uniqID()}Node`}
              nodes={this.props.nodes.children}
            />
          </div>
        )}
      </div>
    );
  }
  render() {
    const { conduct, answers } = this.props;

    if (this.props.nodes) {
      let context = Exp.survey_conduct_context(
        conduct.questions,
        answers,
        conduct.contextVariables
      );

     if (!validateConditionToRoot(this.props.nodes, context)) {
        return "";
      }
    }
    return this.props.showButton ? this.haveShowBtn() : this.notHaveBtn();
  }
}

const mapStateToProps = state => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data
  };
};

export default connect(mapStateToProps)(GroupsWrapperContainer);
