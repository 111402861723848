import React, { Component } from "react";
import parse from 'html-react-parser';
import { addStyleHeaderObject } from "../Utility/index";
import { isNullOrUndefined } from "../Utility/index";
import ValidateComponent from "./ValidateComponent";
import {convertTextToScreen} from "../Utility/ConditionValidator";

class QuestionDetailsComponent extends Component {
  componentDidMount() {
    addStyleHeaderObject(
      {
        [`[id="${this.props.question.elementKey}"]`]: {
          "text-align": this.props.question.alignment
        }
      },
      "mStyle"
    );
  }
  render() {
    const { questionContent, mandatory } = this.props.question;
    return (
      <div className="question_header">
        {questionContent && isNullOrUndefined(questionContent.questionText) ? (
          ""
        ) : (
          <div className="question_separator" />
        )}
        <ValidateComponent {...this.props} />
        {questionContent && isNullOrUndefined(questionContent.questionText) ? (
          ""
        ) : (
          <div className={`question_title`}>
            {isNullOrUndefined(questionContent.questionText) ||
            questionContent.questionText === "" ? (
              ""
            ) : (
              <span className={`question_mandatory`}>
                {mandatory ? "* " : ""}
              </span>
            )}
            <span className="question_text" style={{wordWrap: 'break-word'}}>
              {parse( convertTextToScreen(questionContent.questionText))}
            </span>
          </div>
        )}
        {questionContent && !questionContent.descriptionContent ? null : (
          <div className={"question_description"} style={{wordWrap: 'break-word'}}>
            {parse(convertTextToScreen(questionContent.descriptionContent))}
          </div>
        )}
        {questionContent && !questionContent.helpText ? null : (
          <div className={"question_helptext"}>
            <span>{parse(questionContent.helpText)}</span>
          </div>
        )}
      </div>
    );
  }
}

export default QuestionDetailsComponent;
