import {
  CONDUCT_GET_DATA_FULFILLED,
  CONDUCT_GET_QUESTION_FULFILLED,
  CONDUCT_SET_NODE,
  CONDUCT_GET_DATA_REJECTED,
  CONDUCT_GET_QUESTION_REJECTED,
  CONDUCT_SET_LANGUAGES_FULFILLED,
  CONDUCT_SET_CURRENT_NODE,
  CONDUCT_SET_PAGECHANGE
} from "../ActionTypes/ConductType";
import {
  WELCOME,
  ERROR_500,
  LOADING,
  NOTFOUND,
  EXPIRE,
  DUPLICATE
} from "../Utility/TypeValues";
import {
  modifySurveyFlow,
  surveyFlowArrayLine,
  getNodeWhenSkipWelcome,
  findRecursiveData,
  replaceRecursiveData
} from "../Utility/ConditionValidator";
import {
  checkIsExpire
} from "../Utility";
import { AnswerSkip } from "../Utility/AnswerValidateState";
import { isNullOrUndefined, isArray } from "../Utility/index";
const createClassNameOfLanguage = (action) => {
  if (!isNullOrUndefined(action.payload)) {
      document.body.setAttribute("class",`current-language-${action.payload.currentLanguageID}`);
  }
}

function shuffle(arr) {
  if (!isArray(arr))
    return [];
  return arr.sort(() => Math.random() - 0.5);
}
const conductReducer = (state = {
  node: LOADING,
  currentNode: {},
  renderText: {
    datetime: new Date().toISOString()
  },
  autoNext: {use: false, focusRequire: true},
  aes: false,
  swap: {use: false, questionsName : [] , randomQuestionSwap: []},
  thankyou_show: false
}, action) => {
  createClassNameOfLanguage(action);
  switch (action.type) {
    case CONDUCT_GET_DATA_FULFILLED:
    // case "CONDUCT_GET_DATA":
      if (action.payload && checkIsExpire(action)) {
        state = {
          node: EXPIRE
        };
      } else if (action.payload && action.payload.repeatConduct === true) {
        state = {
          ...action.payload,
          node: DUPLICATE
        };
      } else if (action.payload && action.payload.skipWelcomePage === true) {
        AnswerSkip.keepAnswerState = isNullOrUndefined(action.payload.keepAnswerState)? true : action.payload.keepAnswerState;
        if (!isNullOrUndefined(action.payload.contextVariables) && action.payload.contextVariables.length === 0) {
             action.payload.contextVariables =  ((()=>
             {
                const hashes = window.location.search.replace("?","").split("&");
                const vars = [];
                hashes.forEach((data)=> {
                          const hash = data.split("=");
                          if (hash.length === 2) {
                            vars.push({name:hash[0], value:hash[1]});
                          }
                       });

                return vars;
             })());
        }
        if (state.swap.use) {
          const log = [...state.swap.questionsName];
          state.swap.randomQuestionSwap = shuffle(log);
          const keep = [];
          const q = [];
          const questionsName = [];
          action.payload.questions.forEach(x => {
                 const i = state.swap.randomQuestionSwap
                 .indexOf(x.name);
                 const m = state.swap.questionsName.indexOf(x.name);
                if (i >= 0) {
                   q[i] = x.elementKey;
                }
                if (m >= 0) {
                  questionsName[m] = x.elementKey;
                }
          });

          findRecursiveData(action.payload.surveyFlow, q, keep);
          let test = action.payload.surveyFlow;
          replaceRecursiveData( test, questionsName, keep);
        }

        let data = modifySurveyFlow(action.payload.surveyFlow);
        state = {
          ...state,
          ...action.payload,
          node: 0,
          surveyFlow: action.payload.surveyFlow,
          surveyFlowLog: data,
          surveyFlowArrayLine: surveyFlowArrayLine(action.payload.surveyFlow),
          currentNode: getNodeWhenSkipWelcome(
            data,
            action.payload.questions, {},
            action.payload.contextVariables
          )
        };
      } else if (action.payload && action.payload.skipWelcomePage === false) {
        AnswerSkip.keepAnswerState = isNullOrUndefined(action.payload.keepAnswerState)? true : action.payload.keepAnswerState;
        if (state.swap.use) {
          const log = [...state.swap.questionsName];
          state.swap.randomQuestionSwap = shuffle(log);
          const keep = [];
          const q = [];
          const questionsName = [];
          action.payload.questions.forEach(x => {
                 const i = state.swap.randomQuestionSwap
                 .indexOf(x.name);
                 const m = state.swap.questionsName.indexOf(x.name);
                if (i >= 0) {
                   q[i] = x.elementKey;
                }
                if (m >= 0) {
                  questionsName[m] = x.elementKey;
                }
          });

          findRecursiveData(action.payload.surveyFlow, q, keep);
          let test = action.payload.surveyFlow;
          replaceRecursiveData( test, questionsName, keep);
        }
        let data = modifySurveyFlow(action.payload.surveyFlow);
        state = {
          ...state,
          ...action.payload,
          surveyFlow: action.payload.surveyFlow,
          surveyFlowLog: data,
          surveyFlowArrayLine: data.length === 0 ?
            null :
            surveyFlowArrayLine(action.payload.surveyFlow),
          currentNode: data.length === 0 ? null : data[0],
          node: WELCOME
        };
      } else {
        state = {
          node: NOTFOUND
        };
      }
      break;
    case CONDUCT_GET_QUESTION_FULFILLED:
    //case "CONDUCT_GET_QUESTION":
      state = {
        ...state,
        questions: action.payload
      };
      break;
    case CONDUCT_SET_NODE:
      state = {
        ...state,
        node: action.node
      };
      break;
    case CONDUCT_SET_PAGECHANGE:
      console.log("conduct set pagechange")
      state = {
        ...state,
        pageChange: action.pageChange,
        changeState: action.changeState
      };
      break;
    case CONDUCT_GET_DATA_REJECTED:
    case CONDUCT_GET_QUESTION_REJECTED:
      state = {
        ...state,
        node: ERROR_500,
        error_message: action.payload.message
      };
      break;
    case CONDUCT_SET_LANGUAGES_FULFILLED:
      state = {
        ...state,
        ...action.payload
      };
      break;

    case CONDUCT_SET_CURRENT_NODE:
      state = {
        ...state,
        currentNode: action.currentNode
      };
      break;
    default:
  }

  return state;
};

export default conductReducer;
