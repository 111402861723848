import React from "react";
import parse from 'html-react-parser';
import QuestionDetailsComponent from "./QuestionDetailsComponent";
import { isNullOrUndefined, cloneChoices } from "../Utility/index";

class MultipleChoiceOneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
   // this.textboxChange = _.debounce(this.textboxChange.bind(this), 100);
    this.isChecked = this.isChecked.bind(this);
    this.getAppendText = this.getAppendText.bind(this);
  }

  currentAnswerValue() {
    /* define answer model */
    const { question } = this.props;
    let choice_values = question.choices.map(ch => ({
      elementKey: ch.elementKey,
      choiceText: this.getAppendText(ch),
      checkState: this.isChecked(ch)
    }));
    let new_value = null;
    if (question.includeOther) {
      new_value = {
        isIncludeOther: this.isChecked(question),
        IncludeOtherText: this.getAppendText(question),
        value: choice_values
      };
    } else {
      new_value = choice_values;
    }
    return new_value;
  }

  handleClick(e, choice) {
    const { question } = this.props;
   // const { value } = answer;
    let new_value = this.currentAnswerValue(); //{...value};
    if (question.includeOther) {
      new_value.isIncludeOther = question.elementKey === choice.elementKey;
      new_value.value.forEach(v => {
        v.checkState = v.elementKey === choice.elementKey;
      });
      // clear answer include
      if (document.getElementById(`choicetext${question.elementKey}`)) {
        new_value.IncludeOtherText = "";
        document.getElementById(`choicetext${question.elementKey}`).value = '';
      }
    } else {
      new_value.forEach(v => {
        v.checkState = v.elementKey === choice.elementKey;
      });
    }
    this.props.setAnswer(this.props, new_value);
  }

  textboxChange(e, choice) {
    const { question } = this.props;
 //   const { value } = answer;
    let new_value = this.currentAnswerValue();
    if (question.includeOther) {
      if (question.elementKey === choice.elementKey) {
        new_value.IncludeOtherText = e.target.value;
      } else {
        new_value.value.find(
          v => v.elementKey === choice.elementKey
        ).choiceText =
          e.target.value;
      }
    } else {
      new_value.find(v => v.elementKey === choice.elementKey).choiceText =
        e.target.value;
    }
    this.props.setAnswer(this.props, new_value);
  }


  getAppendText(choice) {
    const { value } = this.props.answer;
    if (
      choice.elementKey === this.props.question.elementKey
    ) {
      return this.props.answer.IncludeOtherText;
    }
    if (!Array.isArray(value)) {
      return "";
    } else {
      const ch = value.find(v => v.elementKey === choice.elementKey);
      if (!ch) {
        return "";
      } else {
        return isNullOrUndefined(ch.choiceText) ? "" : ch.choiceText;
      }
    }
  }
  isChecked(choice) {
    const { value } = this.props.answer;
    if (
      choice.elementKey === this.props.question.elementKey
    ) {
      return this.props.answer.isIncludeOther;
    }

    if (!Array.isArray(value)) {
      return false;
    } else {
      const ch = value.find(v => v.elementKey === choice.elementKey);
      if (!ch) {
        return false;
      } else {
        return ch.checkState;
      }
    }
  }

  render() {
    const { question } = this.props;
    const { multipleChoiceDisplayLayout } = question;
    let choicesCopy = cloneChoices(this.props);
    const choice_group_size =
      multipleChoiceDisplayLayout === "ONE_COLUMN"
        ? 1
        : multipleChoiceDisplayLayout === "TWO_COLUMN" ? 2 : 3;

    const choice_groups = choicesCopy.reduce(
      (groups, choice, i) => {
        let group = groups.pop();
        if (group.choices.length === choice_group_size) {
          groups.push(group);
          group = {
            elementKey: "",
            choices: []
          };
        }
        group.elementKey += choice.elementKey;
        group.choices.push(choice);
        groups.push(group);
        return groups;
      },
      [
        {
          elementKey: "",
          choices: []
        }
      ]
    );

    return (
      <div
        id={`${question.elementKey}`}
        className={`question multiple_choice_one_answer`}
      >
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <div className="choices">
            {choice_groups.map(choice_group => (
              <div key={choice_group.elementKey} className="choice_group">
                {choice_group.choices.map(choice => (
                  <div key={choice.elementKey} className="choice">
                    <input
                      type="radio"
                      id={`choice${choice.elementKey}`}
                      name={`mcoi${this.props.question.elementKey}`}
                      defaultChecked={this.isChecked(choice)}
                      onClick={e => {
                        //  console.log('handle input click');
                        this.handleClick(e, choice);
                      }}
                    />
                    <label>
                      <label  htmlFor={`choice${choice.elementKey}`}  className="checkmark" />
                      {choice.choiceText ? (
                        <label htmlFor={`choice${choice.elementKey}`} className="choice_content">
                          {parse(choice.choiceText)}
                        </label>
                      ) : null}
                      {choice.appendText ? (
                        <div className="append_text_wrapper">
                          <textarea
                            type="text"
                            className="append_text"
                            id={`choicetext${choice.elementKey}`}
                            onBlur={e => {
                              //  console.log('handle append text change');
                              e.persist();
                              e.target.value = e.target.value.trim();
                              this.textboxChange(e, choice);
                            }}
                            defaultValue={this.getAppendText(choice)}
                          />
                        </div>
                      ) : null}

                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleChoiceOneComponent;
