import React, { Component } from "react";
import { connect } from "react-redux";
import NodeContainer from "./NodeContainer";
import Exp from "../Expression/expression";
import { isNull } from "../Utility/index";
import {
  validateConditionToRoot
} from "../Utility/ConditionValidator";

class GroupsContainer extends Component {
  render() {
    const { conduct, answers } = this.props;

    if (this.props.nodes) {
      let context = Exp.survey_conduct_context(conduct.questions, answers, conduct.contextVariables); 

      if (!validateConditionToRoot(this.props.nodes, context)) {
        return "";
      }
    }
    return isNull(this.props.nodes.children) ? (
      ""
    ) : (
      <NodeContainer
        key={`nodecontainer${this.props.nodes.elementKey}`}
        nodes={this.props.nodes.children}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
