import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import conductReducer from './Reducers/ConductReducer';
import appReducer from './Reducers/AppReducer';
import answerReducer from './Reducers/AnswerReducer';
import extraReducer from './Reducers/ExtraReducer';

export default createStore(
  combineReducers({conductReducer, appReducer, answerReducer, extraReducer}),
  {},
  applyMiddleware(
    thunk,
    promise()
  )
);
