import React, { Component } from "react";
import { connect } from "react-redux";
import PagesContainer from "./PagesContainer";
import QuestionContainer from "./QuestionContainer";
import GroupsWrapperContainer from "./GroupsWrapperContainer";
import NotFoundComponent from "../Components/NotFoundComponent";
import ExpireComponent from "../Components/ExpireComponent";
import DuplicateComponent from "../Components/DuplicateComponent";
import InternalErrorComponent from "../Components/InternalErrorComponent";
import IndexComponent from "../Components/IndexComponent";
import LoadingComponent from "../Components/LoadingComponent";
import ThankyouContainer from "../Containers/ThankyouContainer";
import WelcomeContainer from "../Containers/WelcomeContainer";
import RedirectComponent from "../Components/RedirectComponent";
import { parseUrl } from "../Utility/index";
import $ from "jquery-slim";
import {
  NOTFOUND,
  WELCOME,
  ERROR_500,
  LOADING,
  PAGE,
  GROUP,
  QUESTION,
  EXPIRE,
  DUPLICATE,
  INDEX
} from "../Utility/TypeValues";
import { setPageChange } from "../Actions/ConductAction";
import Exp from "../Expression/expression";
import { isNull } from "../Utility/index";

class SurveyFlowContainer extends Component {  
  constructor(props) {
    super(props);
    this.swipedLeft = this.swipedLeft.bind(this);
    this.swipedRight = this.swipedRight.bind(this);
  }

  setNode(elementEtc, conduct) {
    if (elementEtc !== "") return elementEtc;
    
    if (conduct.currentNode.type === PAGE) {
      return <PagesContainer nodes={conduct.currentNode} />;
    } else if (conduct.currentNode.type === GROUP) {
      return (
        <GroupsWrapperContainer nodes={conduct.currentNode} showButton={true} />
      );
    } else if (conduct.currentNode.type === QUESTION) {
      return <QuestionContainer nodes={[conduct.currentNode]} />;
    }
  }

  swipedLeft(e) {
    $("button.button-next").click();
    this.props.setPageChange(false, null);
  }
  swipedRight(e) {
    $("button.button-previous").click();
    this.props.setPageChange(false, null);
  }  

  render() {
    const { conduct, answers } = this.props;
    let elementEtc = "";
    if (conduct.node === LOADING) {
      elementEtc = <LoadingComponent />;
    } else if (conduct.node === INDEX) {
      elementEtc = <IndexComponent />;
    } else if (conduct.node === WELCOME) {
      elementEtc = <WelcomeContainer />;
    } else if (conduct.node === NOTFOUND) {
      elementEtc = <NotFoundComponent />;
    } else if (conduct.node === EXPIRE) {
      elementEtc = <ExpireComponent />;
    } else if (conduct.node === DUPLICATE) {
      elementEtc = <DuplicateComponent conduct={conduct} />;
    } else if (conduct.node === ERROR_500) {
      elementEtc = <InternalErrorComponent message={conduct.error_message} />;
    }

    //Check conditions in all nodes if nodes are not node for render will edit this conditions
    if (
      isNull(conduct.currentNode) &&
      [LOADING, WELCOME, NOTFOUND, ERROR_500].indexOf(conduct.node) < 0
    ) {
      if (conduct.redirectThankyouPage) {
        return <RedirectComponent conduct={conduct} />;
      } else {
        return <ThankyouContainer />;
      }
    }

    if (
      conduct &&
      conduct.hasOwnProperty("surveyFlow") &&
      conduct.surveyFlow.hasOwnProperty("condition")
    ) {
      let context = Exp.survey_conduct_context(
        conduct.questions,
        answers,
        conduct.contextVariables
      );
      // if no question expression show false and skip to thankyou page
      if (!Exp.run_object_context(conduct.surveyFlow.condition, context)) {
        if (conduct.redirectThankyouPage) {
          window.location.href = parseUrl(
            conduct.contextVariables,
            conduct.redirectThankyouPageUrl,
            this.props
          );
          return;
        } else {
          return <ThankyouContainer />;
        }
      }
    }

    return (
      this.setNode(elementEtc, conduct)
    );
  }
}
const mapStateToProps = state => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPageChange: (pageChage, changeState) => {
      dispatch(setPageChange(false, null));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SurveyFlowContainer
);
